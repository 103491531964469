import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { DISABLE_OI, DISABLE_CBM, DISABLE_TXALERT2, DISPLAY_PAYMENT_LINK, DISABLE_3DS, DISABLE_THREEDS_MICROSERVICE } from "./config/config";

import PrivateRoute from "./components/Authenticated";

import Login from "./views/Login";
import Logout from "./views/Logout";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import PageNotFound from "./views/PageNotFound";
import Profile from "./views/Profile";
import Dashboard from "./views/Dashboard";
import Merchants from "./views/Merchants";
import Merchant from "./views/Merchants/Merchant";
import AlertDescriptors from "./views/Alerts/AlertDescriptors";
import AlertDescriptor from "./views/Alerts/AlertDescriptor";
import Descriptors from "./views/Descriptors";
import Descriptor from "./views/Descriptors/Descriptor";
import Transactions from "./views/Transactions";
import Transaction from "./views/Transactions/Transaction";
import VirtualTerminal from "./views/VirtualTerminal";
import NmiCCPayment from "./views/VirtualTerminal/NmiCCPayment";
import NmiACHPayment from "./views/VirtualTerminal/NmiACHPayment";
import Refunds from "./views/Refunds";
import Fraudchecks from "./views/Fraudchecks";
import ThreeDSecures from "./views/ThreeDSecure";
import ThreeDSecure from "./views/ThreeDSecure/ThreeDSecure";
import ThreeDSecureOverview from "./views/ThreeDSecure/Overview";
import ThreeDSecureStats from "./views/ThreeDSecure/Stats";
import ThreeDSecureBinMid from "./views/ThreeDSecure/BinMid";
import Alerts from "./views/Alerts";
import Alert from "./views/Alerts/Alert";
import AlertStats from "./views/Alerts/Stats";
import RDRActivity from "./views/Alerts/RDRActivity";
import BinCaidDescriptors from "./views/Alerts/BinCaidDescriptors";
import Messages from "./views/Messages";
import MerchantBalance from "./views/Merchants/Balance";
import PaymentLink from './views/PaymentLink';
import Chargebacks from "./views/Chargebacks";
import Chargeback from "./views/Chargebacks/Chargeback";
import Chargeback_Merchants from "./views/Chargebacks/Merchants";
import Chargeback_Providers from "./views/Chargebacks/Providers";
import Chargeback_Merchant_Providers from "./views/Chargebacks/MerchantProviders";
import ChargebackMerchant from "./views/Chargebacks/ChargebackMerchant";
import Chargeback_AlertProviders from "./views/Chargebacks/AlertProviders";
import Chargeback_MerchantAlertProviders from "./views/Chargebacks/MerchantAlertProviders";


import OI_Merchants from "./views/OI/Merchants";
import OI_Bincaids from "./views/OI/Bincaids";
import OI_Descriptors from "./views/OI/Descriptors";
import OI_Descriptor from "./views/OI/Descriptor";
import OI_Carts from "./views/OI/Carts";
import OI_Cart from "./views/OI/Cart";
import OI_Lookup from "./views/OI/LookupHistory";

import Txalert_AlertList from "./views/TxAlert/Alert/index"; //its giving error without 'index' specified in the path. not sure why
import TxAlert_Merchants from "./views/TxAlert/Merchants";
import TxAlert_BinCaidList from "./views/TxAlert/BinCaid";
import Txalert_EnrollmentStatusList from "./views/TxAlert/Admin/EnrollmentStatusList";
import Txalert_DisputeConditionCodeList from "./views/TxAlert/Admin/DisputeConditionCodeList";
import Txalert_DescriptorList from "./views/TxAlert/Descriptors";
import Txalert_DescriptorDetail from "./views/TxAlert/Descriptors/DescriptorDetail";
import Txalert_CreditReasons from "./views/TxAlert/Admin/CreditReasons";
import Txalert_CreditStatusList from "./views/TxAlert/Admin/CreditStatusList";
import Txalert_MCCTierList from "./views/TxAlert/Admin/MCCTierList";
import Txalert_AlertProviderList from "./views/TxAlert/Admin/AlertProviderList";
import Txalert_AlertProviderLoginList from "./views/TxAlert/Admin/AlertProviderLogins";
import Txalert_AlertTypeList from "./views/TxAlert/Admin/AlertTypes";
import Txalert_ERProviderList from "./views/TxAlert/Admin/ERProviderList";
import Txalert_ERProviderLoginList from "./views/TxAlert/Admin/ERProviderLoginList";
import Txalert_AlertResponses from "./views/TxAlert/Admin/AlertResponses";
import Txalert_AlertDetail from "./views/TxAlert/Alert/AlertDetail";
import Txalert_ERTransactionMatches from "./views/TxAlert/Admin/ERTransactionMatches";
import Txalert_GenerateAlert from "./views/TxAlert/GenerateAlert";
import Txalert_PostToProviderLogs from "./views/TxAlert/Logs/PostToProviderLogs";
import Txalert_PostToMerchantLogs from "./views/TxAlert/Logs/PostToMerchantLogs";
import Txalert_BinCaidDetail from "./views/TxAlert/BinCaid/BinCaidDetail";
import Txalert_AcquiringBankList from "./views/TxAlert/Admin/AcquiringBankList";
import Txalert_AcquiringBankAccountList from "./views/TxAlert/Admin/AcquiringBankAccountList";
import Txalert_PostToProviderRetryLogs from "./views/TxAlert/Logs/PostToProviderRetryLogs";
import Txalert_PostToMerchantRetryLogs from "./views/TxAlert/Logs/PostToMerchantRetryLogs";
import Txalert_Themes from "./views/TxAlert/Admin/Themes";
import Txalert_EmailTemplates from "./views/TxAlert/Admin/EmailTemplates";
import Txalert_AlertCreditRequests from "./views/TxAlert/Admin/AlertCreditRequests";
import Txalert_MerchantDetail from "./views/TxAlert/Merchants/MerchantDetail";
import Txalert_AlertProviderDetail from "./views/TxAlert/Admin/AlertProviderDetail";
import Txalert_ERTransactionMatch from "./views/TxAlert/Admin/ERTransactionMatch";
import Txalert_IssuerBinList from "./views/TxAlert/Admin/IssuerBinList";
import Txalert_EmailSendLogs from "./views/TxAlert/Logs/EmailSendLogs";

import Txalert_BackgroundProcessLogs from "./views/TxAlert/Logs/BackgroungProcessLog";
import Txalert_ExpressResolveGatewayLogs from "./views/TxAlert/Logs/ExpressResolveGatewayLogs";
import Threeds_Merchant from "./views/ThreeDSecure/Merchant";

class Txshield extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/forgotpassword" component={ForgotPassword} />
                    <Route path="/resetpassword/:token" component={ResetPassword} />

                    <PrivateRoute exact path="/" component={Dashboard} />
                    <PrivateRoute path="/profile" component={Profile} />
                    <PrivateRoute path="/dashboard" component={Dashboard} />
                    <PrivateRoute path="/merchants" component={Merchants} />
                    <PrivateRoute path="/merchant/:id" component={Merchant} />
                    <PrivateRoute path="/alertdescriptors" component={AlertDescriptors} />
                    <PrivateRoute path="/alertdescriptor/:id" component={AlertDescriptor} />
                    <PrivateRoute path="/gatewaydescriptors" component={Descriptors} />
                    <PrivateRoute path="/gatewaydescriptor/:id" component={Descriptor} />
                    <PrivateRoute path="/transactions/:id" component={Transaction} />
                    <PrivateRoute path="/transactions" component={Transactions} />
                    <PrivateRoute path="/virtualterminal" component={VirtualTerminal} />
                    <PrivateRoute path="/virtualterminal-cliq-cc" component={NmiCCPayment} />
                    <PrivateRoute path="/virtualterminal-cliq-ach" component={NmiACHPayment} />
                    <PrivateRoute path="/refunds" component={Refunds} />
                    <PrivateRoute path="/fraudchecks" component={Fraudchecks} />
                    <PrivateRoute path="/alerts" component={Alerts} />
                    <PrivateRoute path="/alert/:id" component={Alert} />
                    <PrivateRoute path="/alertstats" component={AlertStats} />
                    <PrivateRoute path="/rdractivity" component={RDRActivity} />
                    <PrivateRoute path="/bincaiddescriptors" component={BinCaidDescriptors} />
                    <PrivateRoute path="/messages" component={Messages} />
                    <PrivateRoute path="/chargebacks" component={Chargebacks} />
                    <PrivateRoute path="/chargeback/:id" component={Chargeback} />
                    <PrivateRoute path="/balance" component={MerchantBalance} />

                    {!DISABLE_3DS ? <PrivateRoute path="/3ds_transaction/:id" component={ThreeDSecure} /> : null}
                    {!DISABLE_3DS ? <PrivateRoute path="/3ds_transaction" component={ThreeDSecures} /> : null}

                    {!DISABLE_THREEDS_MICROSERVICE ? <PrivateRoute path="/3dsecure_merchant" component={Threeds_Merchant} /> : null}
                    {!DISABLE_THREEDS_MICROSERVICE ? <PrivateRoute path="/3dsecure/:id" component={ThreeDSecure} /> : null}
                    {!DISABLE_THREEDS_MICROSERVICE ? <PrivateRoute path="/3dsecure_overview" component={ThreeDSecureOverview} /> : null}
                    {!DISABLE_THREEDS_MICROSERVICE ? <PrivateRoute path="/3dsecure" component={ThreeDSecures} /> : null}
                    {!DISABLE_THREEDS_MICROSERVICE ? <PrivateRoute path="/3dsstats" component={ThreeDSecureStats} /> : null}
                    {!DISABLE_THREEDS_MICROSERVICE ? <PrivateRoute path="/3dsbinmid" component={ThreeDSecureBinMid} /> : null}

                    {DISPLAY_PAYMENT_LINK ? <PrivateRoute path="/paymentlink" component={PaymentLink} /> : null}


                    {!DISABLE_CBM ? <PrivateRoute path="/chargeback_merchants" component={Chargeback_Merchants} /> : null}
                    {!DISABLE_CBM ? <PrivateRoute path="/chargeback_providers" component={Chargeback_Providers} /> : null}
                    {!DISABLE_CBM ? <PrivateRoute path="/chargeback_merchant_providers" component={Chargeback_Merchant_Providers} /> : null}                    
                    {!DISABLE_CBM ? <PrivateRoute path="/chargeback_merchant/:id" component={ChargebackMerchant} /> : null}
                    {!DISABLE_CBM ? <PrivateRoute path="/chargebacks" component={Chargebacks} /> : null}
                    {!DISABLE_CBM ? <PrivateRoute path="/chargeback/:id" component={Chargeback} /> : null}
                    {!DISABLE_CBM ? <PrivateRoute path="/chargeback_alertproviders" component={Chargeback_AlertProviders} /> : null}
                    {!DISABLE_CBM ? <PrivateRoute path="/chargeback_alertprovider_logins" component={Chargeback_MerchantAlertProviders} /> : null}

                    {!DISABLE_OI ? <PrivateRoute path="/txfind_merchants" component={OI_Merchants} /> : null}
                    {!DISABLE_OI ? <PrivateRoute path="/txfind_bincaids" component={OI_Bincaids} /> : null}
                    {!DISABLE_OI ? <PrivateRoute path="/txfind_descriptors" component={OI_Descriptors} /> : null}
                    {!DISABLE_OI ? <PrivateRoute path="/txfind_descriptor/:id" component={OI_Descriptor} /> : null}
                    {!DISABLE_OI ? <PrivateRoute path="/txfind_carts" component={OI_Carts} /> : null}
                    {!DISABLE_OI ? <PrivateRoute path="/txfind_cart/:id" component={OI_Cart} /> : null}
                    {!DISABLE_OI ? <PrivateRoute path="/txfind_carts_lookup" component={OI_Lookup} /> : null}


                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/alertlist" component={Txalert_AlertList} />:null}
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/merchantlist" component={TxAlert_Merchants} />:null}
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/merchantdetails/:id" component={Txalert_MerchantDetail} />:null} 
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/bincaidlist" component={TxAlert_BinCaidList} />:null} 
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/bincaid/:id" component={Txalert_BinCaidDetail} />:null} 
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/enrollmentstatuslist" component={Txalert_EnrollmentStatusList} />:null}
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/disputeconditioncodelist" component={Txalert_DisputeConditionCodeList} />:null}     
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/descriptorlist" component={Txalert_DescriptorList} />:null}     
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/descriptor/:id" component={Txalert_DescriptorDetail} />:null}     
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/creditreasonlist" component={Txalert_CreditReasons} />:null}     
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/creditstatuslist" component={Txalert_CreditStatusList} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/mcctierlist" component={Txalert_MCCTierList} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/alertproviderlist" component={Txalert_AlertProviderList} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/alertproviderdetail/:id" component={Txalert_AlertProviderDetail} />:null} 
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/alertproviderlogins" component={Txalert_AlertProviderLoginList} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/alerttypelist" component={Txalert_AlertTypeList} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/erproviderlist" component={Txalert_ERProviderList} />:null}
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/erproviderlogins" component={Txalert_ERProviderLoginList} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/alertresponses" component={Txalert_AlertResponses} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/alertdetail/:id" component={Txalert_AlertDetail} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/ertransactionmatches" component={Txalert_ERTransactionMatches} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/generatealert" component={Txalert_GenerateAlert} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/posttoalertproviderlogs" component={Txalert_PostToProviderLogs} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/posttomerchantlogs" component={Txalert_PostToMerchantLogs} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/acquiringbanklist" component={Txalert_AcquiringBankList} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/acquiringbankaccountlist" component={Txalert_AcquiringBankAccountList} />:null} 
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/posttoalertproviderretrylogs" component={Txalert_PostToProviderRetryLogs} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/posttomerchantretrylogs" component={Txalert_PostToMerchantRetryLogs} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/themelist" component={Txalert_Themes} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/emailtemplatelist" component={Txalert_EmailTemplates} />:null}
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/creditrequests" component={Txalert_AlertCreditRequests} />:null}
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/ertransactionmatch/:id" component={Txalert_ERTransactionMatch} />:null}  
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/issuerbinlist" component={Txalert_IssuerBinList} />:null}     
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/emailsendlogs" component={Txalert_EmailSendLogs} />:null}  

                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/backgroundprocesslogs" component={Txalert_BackgroundProcessLogs} />:null}
                    {!DISABLE_TXALERT2 ? <PrivateRoute path="/expressresolvelogs" component={Txalert_ExpressResolveGatewayLogs} />:null}

                    <Route path="*" component={PageNotFound} />
                </Switch>
            </Router>
        );
    }
}
export default Txshield;

ReactDOM.render(<Txshield />, document.getElementById("root"));