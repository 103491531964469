module.exports = {
    en: {
        translation: {
            login: {
                email: "Email",
                username: "Username",
                password: "Password",
                login: "Login",
                submit: "Submit",
                continue: "Continue",
                forgotpassword: "Forgot your password?",
                backtologin: "Back to sign in screen",
                verification: {
                    verify: "Verify",
                    gotit: "Got it!",
                    verificationcode: "Verification Code",
                    entercode: "Enter Authenticator code.",
                    emailcode:
                        "We have sent a verification code to the registered email address for this user account. Please check your email and provide the code below.",
                    howtosetup: "How to setup two factor authontication",
                    howtosetup_title: "Two Factor Authentication Setup",
                    howtosetup_text:
                        "You must setup an authontication app like Google authonticator or FreeOTPbegore ccontinuing. You will be unable to login otherwise.",
                    noqrcode_title: "Set up authenticator application",
                    noqrcode_text: "Please contact to admin.",
                    cantscan_click: "Can't Scan? Click Here",
                    copied: "Copied",
                    copy_secretkey: "Copy Secret Key",
                    scan_qrcode_below: "Scan the QR code below and submit when done",
                },
                required: {
                    email: "Please input your Email!",
                    username: "Please input your Username!",
                    password: "Please input your Password!",
                },
                dialogs: {
                    login_error: "Username or password is incorrect.",
                    verify_error: "Verify code is incorrect.",
                    login_success: "Logged in successfully.",
                    reset_password: "Request to reset password send to your account email.",
                    password_changed: "Password has been updated.",
                    groups_changed: "Groups has been updated.",
                    profile_changed: "Profile has been updated.",
                    catch: "Something went wrong!",
                },
                session: {
                    title: "Session Expiring",
                    desc_1: "You will be logged out in",
                    desc_2: "seconds.",
                    desc_3: "Click OK to continue.",
                    expired: "Session Expired",
                },
            },
            resetpassword: {
                title: "Reset Password",
            },
            header: {
                menu: {
                    profile: "Profile",
                    logout: {
                        title: "Logout",
                        question: "Are you sure you want to log out?",
                        yes: "Yes",
                        no: "No",
                    },
                },
                clock: "System Time",
            },
            sidebar: {
                dashboard: "Dashboard",
                merchants: "Merchants",
                merchant: "Merchant",
                txfind_bincaids: "TxFind BIN/CAID",
                bincaids: "BIN/CAID",
                descriptors: "Descriptors",
                descriptor: "Descriptor",
                alertdescriptors: "Descriptors",
                bincaiddescriptors: "BIN/CAID",
                payments: "Payments",
                transactions: "Transactions",
                virtualterminal: "Virtual Terminal",
                'virtualterminal-cliq-cc': "Virtual Terminal - Cliq CC",
                'virtualterminal-cliq-ach': "Virtual Terminal - Cliq ACH",
                fraudchecks: "Fraudchecks",
                refunds: "Refunds",
                "3dsecure": "3DS",
                "3dsecure_microservice": "3DS Secure",
                "3dsecure_overview": "Overview",
                "3dsecure_merchant": 'Merchants',
                alerts: "Alerts",
                alertstats: "Alerts Statistics",
                rdractivity: "RDR Activity",
                messages: "Messages",
                userguide: "User Guide",
                chargebacks: "Chargebacks",
                alertsforreview: "Alerts for Review",
                order_insight: "TxFind",
                carts: "Carts",
                lookup: "Lookup History",
                providers: "Providers",
                merchant_providers: "Merchant Providers",
                statistics: "Statistics",
                "3dsstats": "3DSecure Statistics",
                alert: "Alert Detail",
                chargeback: "Chargeback",
                chargeback_merchants: "Chargeback Merchants",
                chargeback_providers: "Chargeback Providers",
                chargeback_merchant_providers: "Chargeback Merchant Providers",
                oi_merchants: "TxFind Merchants",
                oi_carts: "TxFind Carts",
                oi_carts_lookup: "TxFind Lookup History",
                oi_cart: "TxFind Cart Detail",
                txfind_merchants: "TxFind Merchants",
                txfind_descriptors: "TxFind Descriptors",
                txfind_descriptor: "TxFind Descriptor Detail",
                txfind_carts: "TxFind Carts",
                txfind_carts_lookup: "TxFind Lookup History",
                txfind_cart: "TxFind Cart Detail",
                profile: "Profile",
                virtual_terminal: "Virtual Terminal",
                alertdescriptor:"Alert Descriptor",
                binmid:"BIN / MID",
                "3dsbinmid":"BIN / MID",
                chargeback_merchant: "Chargeback Merchant",
                alertproviders:"Alert Providers",
                merchantalertproviders:"Merchant Alert Providers",
                chargeback_alertprovider_logins:"Merchant Alert Providers",
                chargeback_alertproviders:"Alert Providers",
                txalert:"Alert 2.0",
                alertlist:"Alert Listing",
                merchantlist:"Merchant Listing",
                bincaidlist:"Bincaid Listing",
                enrollmentstatuslist:"Enrollment Status List",
                disputeconditioncodelist:"Dispute Condition Code List",
                alerttypelist:"Alert Types",
                admin:"Admin",
                descriptorlist:"Descriptor List",
                creditreasonlist:"Credit Reason List",
                creditstatuslist:"Credit Status List",
                mcctierlist:"MCC Tier List",
                alertproviderlist:"Alert Provider List",
                alertproviderlogins:"Alert Provider Login List",
                erproviders:"ER Providers",
                erproviderlogins:"ER Providers Logins",
                alertresponses:"Alert Responses",
                ertransactionmatches:"ER Matches",
                alertdetail:"Alert Detail",
                generatealert:"Generate Alert",
                logs:"Logs",
                posttoalertproviderlogs:"Post to Alert Provider",
                bincaid:"BIN/CAID",
                acquiringbanklist:"Acquiring Bank List",
                acquiringbankaccountlist:"Acquiring Bank Account List",
                posttomerchantlogs:"Post to Merchant",
                posttoalertproviderretrylogs:"Post to Alert Provider Retry",
                posttomerchantretrylogs:"Post to Merchant Retry",
                themelist:"Themes",
                emailtemplatelist:"Email Templates",
                creditrequests:"Credit Requests",
                merchantdetails:"Merchant Details",
                alertproviderdetail:"Alert Provider Detail",
                erproviderlist:"ER Providers",
                ertransactionmatch:"ER Transaction Match",
                issuerbinlist:"Issuer Bin List",
                emailsendlogs:"Email Send Logs",
                backgroundprocesslogs:"Background Process Logs",
                expressresolvelogs:"Express Resolve Gateway Logs",
                balance: "Balance",
                paymentlink: 'Payment Links',
            },
            contents: {
                dashboard: {
                    total: "Total",
                    today: "Today",
                    alerts: "Alerts",
                    alert_today: "Alert Today",
                    alerts_today: "Alerts Today",
                    alertsfor: "Alerts for ",
                    type: "Alert Type",
                    title: "Dashboard",
                    "3dsecure": "3DS",
                    fraudchecks: "Fraudchecks",
                    transactions: "Transactions",
                    alerts_chart2: "Alerts - Total Alerts",
                    transaction_count: "Transaction Count",
                    total_revenue_collected: "Total Revenue Collected",
                    total_counts_of_3dsecure: "3DS Total Counts",
                    total_counts_of_fraudcheck: "Total Counts",
                    avg_ticket_value_of_transaction: "Avg. Ticket Value",
                    avg_ticket_value_of_fraudcheck: "Avg. Ticket Value",
                    expiring: "Expiring Today",
                    action_required: "Action Required",
                    wip: "WIP",
                    alert_info: "Alert Info",
                    transaction_info: "Transaction Info",
                },
                days: {
                    sun: "Sun",
                    mon: "Mon",
                    tue: "Tue",
                    wed: "Wed",
                    thu: "Thu",
                    fri: "Fri",
                    sat: "Sat",
                },
                months: {
                    january: "January",
                    february: "February",
                    march: "March",
                    april: "April",
                    may: "May",
                    june: "June",
                    july: "July",
                    august: "August",
                    september: "September",
                    october: "October",
                    november: "November",
                    december: "December",
                },
                resellers: {
                    title: "Resellers",
                },
                retailers: {
                    title: "Retailers",
                },
                sites: {
                    title: "Sites",
                },
                merchants: {
                    title: "Merchants",
                    merchant: "Merchant",
                    details: "Details",
                    retailer_details: "Retailer Details",
                    payment: "Payment",
                    "3ds": "3DS",
                    alerts: "Alerts",
                    settings: "Settings",
                    access_records: "Access Records",
                    bincaid: "Bincaid",
                    descriptor: "Descriptors",
                    balance:"Merchant Balance",
                    form: {
                        sid: "SID",
                        name: "Name",
                        view: "View",
                        rcode: "Rcode",
                        theme: "Theme",
                        status: "Status",
                        active: "Active",
                        remarks: "Remarks",
                        generate: "Generate",
                        inactive: "Inactive",
                        reseller: "Reseller",
                        description: "Description",
                        create_reseller: "Create Reseller",
                        txfind_merchant_id: "TxFind Merchant ID",
                        generate_new_rcode: "Generate New Rcode",
                        please_select_retailer: "Please select retailer",
                        please_select_site: "Please select site",
                        please_select: "Please select",
                        site_url: "Site URL(http address)",
                        site_rcode: "Site Rcode",
                        associated_users: "Associated Users",
                        stop_send_email: "Stop Emails from SID",
                        cbreportemail: "Email Address For Chargebacks Report",
                        available_balance_for_withdraw: "Available Balance For Withdraw",
                        category: "Category",
                        support_group: "Support Group",
                        fraud_template: "Fraud Template",
                        skip_ccv: "Skip CCV Check",
                        bin_currency: "Use BIN to choose pay currency",
                        settlement_currency: "Settlement Currency",
                        allow_rebilling: "Allow Rebilling",
                        proceedeveniffail: "Continue Process on fail",
                        margin_percentage: "Settlement Margin Percentage (%)",
                        alert_on_next_approved_tranaction: "Alert on next approved transaction",
                        to_address: "To Address",
                        allow_refund: "Allow Refund",
                        allow_refund_time: "Unlimited Refund Time",
                        emaildomain: "Unlimited Refund Time Not Active Sendgrid authenticated email domain(receipt email, support email)",
                        shippingntracking: "Shipping & Tracking Enabled",
                        nerver: "Never",
                        perday: "per day",
                        unlimited: "Unlimited",
                        notactive: "Not Active",
                        days: "Day(s)",
                        app: "App",
                        brw: "Browser",
                        "3ri": "3DS Requestor Initiated (3RI)",
                        yes: "Yes",
                        no: "No",
                        payment_transaction: "Payment transaction",
                        recurring_transaction: "Recurring transaction",
                        instalment_transaction: "Instalment transaction",
                        add_card: "Add card",
                        maintain_card: "Maintain card",
                        cardholder_verification: "Cardholder verification as part of EMV token ID & V",
                        not_applicable: "Not Applicable",
                        credit: "Credit",
                        debit: "Debit",
                        gs: "Goods/Service Purchase",
                        ca: "Check Acceptance",
                        af: "Account Funding",
                        qt: "Quasi-Cash Transaction",
                        pal: "Prepaid Activation and Load",
                        paay: "Paay",
                        gpay: "Gpay",
                        hitrust: "HiTrust",
                        verbose: "Verbose mode on",
                        client_mode: "Client mode",
                        enable_result: "Enable result",
                        disable_challenge: "Disable challenge",
                        enable3ds1_frictionless: "Enable 3ds1 frictionless",
                        enable3DS1_only: "Enable 3DS 1.0 only",
                        requestor_name: "Requestor name",
                        requestor_url: "Requestor URL",
                        authentication_indicator: "3DS Requestor Authentication Indicator",
                        challenge_indicator: "3DS Requestor Challenge Indicator",
                        acct_type: "Account Type",
                        acquirer_bin: "Acquiring Bank BIN",
                        acquirer_mid: "Acquirer Merchant ID",
                        master_acquirer_bin: "Mastercard Acquiring Bank BIN",
                        master_acquirer_mid: "Mastercard Acquirer Merchant ID",
                        jcb_acquirer_bin: "JCB Acquiring Bank BIN",
                        jcb_acquirer_mid: "JCB Acquirer Merchant ID",
                        cup_acquirer_bin: "CUP Acquiring Bank BIN",
                        cup_acquirer_mid: "CUP Acquirer Merchant ID",
                        amex_acquirer_bin: "AMEX Acquiring Bank BIN",
                        amex_acquirer_mid: "AMEX Acquirer Merchant ID",
                        acquirer_mid_pwd: "Acquirer Merchant ID Password",
                        merchant_category_code: "Merchant Category Code",
                        merchant_country_code: "Merchant Country Code",
                        merchant_name: "Merchant Name",
                        message_category: "Message Category",
                        purchase_currency: "Purchase Currency",
                        trans_type: "Transaction Type",
                        skip3d2: "Skip 3D2.0 (Overwritten by gateway setting)",
                        three_ds_provider: "3Ds Provider",
                        threedsprotocolversion: "3DS protocol version",
                        paayauth3d2: "Paay APIkey | Secret",
                        gpay_merchant_token: "Gpay Merchant Token",
                        gpay_merchant_id: "Gpay Merchant ID",
                        gpay_base_url: "Gpay URL",
                        hitrust_account_id: "HiTrust Account ID",
                        hitrust_password: "HiTrust Password",
                        hitrust_base_url: "HiTrust Base Url",
                        hitrust_mastercard_prefix: "HiTrust Mastercard Requestor Prefix",
                        alertapiurl: "Alert api url",
                        descriptorid: "Descriptor ID",
                        username: "Username",
                        token: "Token",
                        default_dispute_action: "Default Dispute Action",
                        alert_triggeremail: "Email for alert",
                        alert_email: "Email for alert notification",
                        shieldrdr_review_email: "Email for express resolve review notification",
                        statusupdate_url: "Alert Status Update url",
                        match: "Match only",
                        match_and_refund: "Match and refund",
                        api_username:"API Username",
                        api_password:"API Password",
                        max_refund_amount:"Max Refund Amount",
                        eligible_for_refund:"Eligible for refund",
                        created_date:"Created Date",
                        isactive:"Is Active?",
                        submit:"Submit",
                        new_merchant:"New Merchant",
                        cancel: "Cancel",
                        add_merchant:"Add merchant",
                        view_merchant:"View merchant",
                        enrolled_services:"Enrolled Services"
                    },
                },
                descriptors: {
                    title: " Alert Descriptors",
                    details: "Descriptor Details",
                    tabs: {
                        details: "Details",
                        enrollment: "Enrollment",
                        enrollment_history: "Enrollment History",
                        expressresolve: "Dispute Intelligence",
                        rdr:"RDR",
                        variations:"Variations",
                        table:{
                            status: "Status",
                            status_reason: "Status Reason",
                            providername: "Provider",
                            requested_date: "Requested Date",
                            enrollment_date: "Enrollment Date",
                            update_date: "Update Date",
                            effective_date: "Effective Date",
                            datetime: "Date Time",
                            date: "Date",
                            gatewayname: "Gateway",
                            gatewaydescriptor: "Gateway Descriptor",
                            terminalid: "Terminal ID(MID)",
                            bin:"BIN",
                            caid:"CAID"
                        }
                    },
                    form: {
                        descriptor_id : "Descriptor ID",
                        descriptor : "Descriptor",
                        active: "Active",
                        site_name: "Site",
                    }
                },
                bincaids: {
                    title: "BIN/CAID",
                    view_bincaid: "View BIN/CAID",
                    details: "Details",
                    enrollment_history: "Enrollment History",
                    ruleset_history: "Ruleset History",
                    tabs:{
                        enrollment:"Enrollment"
                    },
                    form: {
                        bin: "BIN",
                        caid: "CAID",
                        mcc: "MCC",
                        tier: "Tier",
                        vrol_profile: "VROL Profile",
                        descriptor: "Descriptor",
                        request_date: "Requested Date",
                        update_date: "Updated Date",
                        status: "Status",
                        status_reason: "Status Reason",
                        reference_no: "Reference Number",
                        new_bincaid: "New Bin Caid",
                        add_bincaid: "Add Bin Caid",
                        view_bincaid: "View Bin Caid",
                        submit:"Submit",
                        submitting:"Submitting",
                        cancel:"Cancel",
                        merchant:"Merchant",
                        rule_set:"Rule Set",
                        comment:"Comment",
                        id:"ID",
                        active:"Active"
                    }
                },
                transactions: {
                    title: "Transactions",
                    details: "Transaction Details",
                    summary: "Transaction Summary",
                    pending_refunds: "Pending Refunds",
                    approved_count: "Approved Count",
                    approved_sum:"Approved Sum",
                    declined_count:"Declined Count",
                    declined_sum:"Declined Sum",
                    total:"Total",
                    action:"Action",
                    tabs: {
                        log: "Transaction Log",
                        tx_history: "TX History",
                        tx_details: "TX Details",
                        payee_details: "Payee Details",
                        tx_items: "TX Items",
                        fraudcheck_details: "Fraudcheck Details",
                        communication_details: "Communication Details",
                        tracking_details: "Tracking Details",
                    },
                    form: {
                        transaction_log: "Transaction Log",
                        card_details: "Card Details",
                        settlement_details: "Settlement Details",
                        cart_and_processing_details: "Cart & Processing Details",
                        user_details: "User Details",
                        shipping_details: "Shipping Details",
                        other: "Other",
                        fraudcheck_details: "Fraudcheck Details",
                        communication_details: "Communication Details",
                        tracking_details: "Tracking Details",
                        no_fraudcheck_information: "No fraudcheck information on this transaction.",
                        no_communication_information: "No communication information on this transaction.",
                        no_tracking_details: "No tracking details on this transaction.",
                        no_transaction_log: "No transaction log on this transaction.",
                        card_type: "Card Type",
                        card_name: "Card Name",
                        card_no: "Card No.",
                        card_expiry: "Card Expiry",
                        start_date: "Start Date",
                        email_address: "Email Address",
                        ip_address: "IP Address",
                        auth_code: "Auth Code",
                        account_type: "Account Type",
                        account_name: "Account Name",
                        account_no: "Account No",
                        routing_no: "Routing No.",
                        purse_no: "Purse No.",
                        arn: "ARN",
                        settlement_amount: "Gross Settlement Amount",
                        reserve: "Reserve",
                        fees: "Fees",
                        sitereceive: "Payable to Merchant",
                        cartmargin: "Cart Margin",
                        margin: "Margin",
                        currbalance: "Current Balance",
                        origtotal: "Amount Total",
                        origpurch: "Payment Amount",
                        origship: "Shipping Amount",
                        origtax: "Tax Amount",
                        origcoupon: "Coupon Amount",
                        processedamount: "Processed Amount",
                        firstname: "First Name",
                        lastname: "Last Name",
                        phoneno: "Phone No.",
                        mobileno: "Mobile No.",
                        address: "Address",
                        suburb: "City/Suburb",
                        state: "State",
                        postcode: "Postcode",
                        country: "Country",
                        tid: "Merchant Reference (TID)",
                        wid: "Operator ID (WID)",
                        info: "Additional Info",
                        ipay: "iPayInfo",
                        ref1: "Reference 1",
                        ref2: "Reference 2",
                        ref3: "Reference 3",
                        ref4: "Reference 4",
                        affiliateid: "Affiliate ID",
                        threat_level_assessment: "Threat level assessment",
                        rule: "Rule",
                        error: "Error",
                        response: "Response",
                        generates: "generates",
                    },
                },
                fraudchecks: { title: "Fraudchecks" },
                refunds: { title: "Refunds" },
                "3dsecure": {
                    title: "3DSecure - Transactions",
                    details: "3DSecure - Details",
                    create_binmid: "Create new BIN / MID",
                    view_binmid: "View BIN / MID",
                    imports_binmid: "Import BIN / MID",
                    tabs: {
                        "3DS_details": "3DS Details",
                    },
                    binmid: {
                        title: "BIN / MID",
                        binmidlist: "BIN / MID List",
                        new_binmid: "New BinMid",
                        added: "BINMID successfully added",
                        updated: "BINMID has been Updated"
                    },
                    merchant: {
                        title: '3DS Merchants',
                        merchantlist: "3DS Merchants List",
                        new_merchant: 'New 3DS Merchant',
                    },
                    form: {
                        "3ds_authorization_details": "3DS Authorization Details",
                        "3ds_log_details": "3DS Log Details",
                        "3DS20": "3DS2.0",
                        acctNumber: "Card No.",
                        purchaseAmount: "Amount",
                        threeDSIntegratorOid: "ThreeDS Integrator Oid",
                        threeDSRequestorOrderID: "ThreeDS Requestor OrderID",
                        threeDSRequestorData: "ThreeDS Requestor Data",
                        threeDSRequestorID: "ThreeDS Requestor ID",
                        threeDSServerTransID: "ThreeDS Server Trans ID",
                        acsTransID: "ACS Trans ID",
                        dsTransID: "DS Trans ID",
                        transStatus: "Trans Status",
                        transStatusReason: "Trans Status Reason",
                        authenticationValue: "Authentication Value",
                        authenticationAlgorithm: "Authentication Algorithm",
                        eci: "ECI",
                        version: "Version",
                        errCode: "Error Code",
                        errMsg: "Error Msg",
                        shield_threeds_ref_diaplay: "TxShield 3DS Reference",
                        shield_threeds_merchant_ref_display: "Merchant Reference",
                        shield_threeds_provider_ref_display: "3DS Provider Reference",
                        shield_threeds_ptxid_display: "TxShield 3DS PTXID",
                        shield_threeds_pay_ptxid_display: "TxShield Payment PTXID",
                        threeDSShieldInitLog: "Send Log Data",
                        threeDSShieldNotifyLog: "Return Log Data",
                        binmidID: "BINMID ID",
                        bin: "BIN",
                        mid: "MID",
                        mcc: "MCC",
                        card_type: "Card Type",
                        status: "Status",
                        date_added: "Date Added",
                        site_name: "Site",
                        cancel: "Cancel",
                        submit: "Submit",
                        submitting: "Submitting",
                        please_select: "Please select"
                    },
                },
                _3dsecure_overview: {
                    title: "3DSecure Overview",
                    filter_by_site: "Filter by Site",
                    filter_by_status: "Filter by Status",
                    pass: "Pass",
                    fail_others: "Fail (Others)",
                    no_data: "No Data",
                    today: "Today",
                    last7DaysPerHour: "Last 7 Days per hour",
                    last7DaysPerDay: "Last 7 Days per day",
                    last30DaysPerDay: "Last 30 Days per day"
                },
                _3dsecure_stats: {
                    title: "3DSecure Statistics",
                    please_select_sid: "Please Select Site",
                    please_select_report_type: "Please Select Report Type",
                    short_status: "Status",
                    short_reason: "Reason",
                    short_status_and_reason: "Status & Reason",
                    count: "Count",
                    explanation: "Explanation",
                    status: "Breakdown By Status",
                    reason: "Breakdown By Reason",
                    status_and_reason: "Breakdown By Status & Reason",
                    other: 'Other',
                    status_total: 'Total',
                    status_explanation: {
                        y: "Authentication Successful",
                        a: "Not Authenticated, but a proof of attempted authentication is provided",
                        c: "Challenge required; additional authentication is required. Only returned if challenge disabled (frictionless)",
                        u: "Authentication/account verification could not be performed",
                        r: "Authentication rejected; issuer is rejecting authentication and requesting authorisation not be attempted",
                        n: "Not authenticated, transaction denied",
                        total: "Total transactions from all status"
                    },
                    status_reason_explanation: {
                        "1": "Card authentication failed",
                        "2": "Unknown Device",
                        "3": "Unsupported Device",
                        "4": "Exceeds authentication frequency limit",
                        "5": "Expired card",
                        "6": "Invalid card number",
                        "7": "Invalid transaction",
                        "8": "No card record",
                        "9": "Security failure",
                        "10": "Stolen card",
                        "11": "Suspected fraud",
                        "12": "Transaction not permitted to cardholder",
                        "13": "Cardholder not enrolled in service",
                        "14": "Transaction timed out at the ACS",
                        "15": "Low confidence",
                        "16": "Medium confidence",
                        "17": "High confidence",
                        "18": "Very High confidence",
                        "19": "Exceeds ACS maximum challenges",
                        "20": "Non-Payment transaction not supported",
                        "21": "3RI transaction not supported",
                        other: "Unexpected cause"
                    }
                },
                _3dsecure_merchant: {
                    new_merchant: 'New 3DS Merchant',
                    edit_merchant: 'Edit 3DS Merchant',
                    form: {
                        merchant_detail: "Merchant detail",
                        site_detail: "Site detail",
                        "3ds_setting": "3DS Settings",
                        name: "Name",
                        description: "Description",
                        remarks: "Remarks",
                        contact: "Contact",
                        status: 'Status',
                        status_option_active: 'Active',
                        status_option_deactivated: 'Deactivated',
                        provider: 'Provider',
                        site_currency: 'Currency',
                        site_url: 'Url',
                        verbose_mode: 'Verbose mode on',
                        client_mode: 'Client mode',
                        client_mode_option_app: 'App',
                        client_mode_option_brw: 'Browser',
                        client_mode_option_3ri: '3DS Requestor Initiated (3RI)',
                        enable_result: 'Enable result',
                        enable_result_option_y: "Yes",
                        enable_result_option_n: "No",
                        disable_challenge: 'Disable challenge',
                        disable_challenge_option_y: "Yes",
                        disable_challenge_option_n: "No",
                        enable_3ds1_friction_less: "Enable 3DS 1.0 FrictionLess",
                        enable_3ds1_friction_less_option_y: "Yes",
                        enable_3ds1_friction_less_option_n: "No",
                        enable_3ds1_only: "Enable 3DS 1.0 only",
                        enable_3ds1_only_option_y: "Yes",
                        enable_3ds1_only_option_n: "No",
                        requestor_name: 'Requestor Name',
                        requestor_url: 'Requestor Url',
                        "3DS_requestor_authentication_indicator": "3DS Requestor Authentication Indicator",
                        "3DS_requestor_authentication_indicator_option_payment_transaction": "Payment Transaction",
                        "3DS_requestor_authentication_indicator_option_recurring_transaction": "Recurring Transaction",
                        "3DS_requestor_authentication_indicator_option_installment_transaction": "Installment Transaction",
                        "3DS_requestor_authentication_indicator_option_add_card": "Add Card",
                        "3DS_requestor_authentication_indicator_option_maintain_card": "Maintain Card",
                        "3DS_requestor_authentication_indicator_option_cardholder_verification": "Cardholder verification as part of EMV token ID&V",
                        "3DS_requestor_challenge_indicator": "3DS Requested Challenge Indicator",
                        "3DS_requestor_challenge_indicator_option_payment_transaction": "Payment Transaction",
                        "3DS_requestor_challenge_indicator_option_recurring_transaction": "Recurring Transaction",
                        "3DS_requestor_challenge_indicator_option_installment_transaction": "Installment Transaction",
                        "3DS_requestor_challenge_indicator_option_add_card": "Add Card",
                        acct_type: "Account Type",
                        acct_type_option_not_applicable: "Not Applicable",
                        acct_type_option_credit: "Credit",
                        acct_type_option_debit: "Debit",
                        visa_acquiring_bank_bin: "Visa Acquiring Bank Bin",
                        visa_acquirer_merchant_id: "Visa Acquirer Merchant ID",
                        mastercard_acquiring_bank_bin: "Mastercard Acquiring Bank BIN",
                        mastercard_acquirer_merchant_id: "Mastercard Acquirer Merchant ID",
                        jcb_acquiring_bank_bin: "JCB Acquiring Bank Bin",
                        jcb_acquirer_merchant_id: "JCB Acquirer Merchant ID",
                        cup_acquiring_bank_bin: "CUP Acquiring Bank BIN",
                        cup_acquirer_merchant_id: "CUP Acquirer Merchant ID",
                        amex_acquiring_bank_bin: "AMEX Acquiring Bank Bin",
                        amex_acquirer_merchant_id: "AMEX Acquirer Merchant ID",
                        acquirer_merchant_id_password: "Acquirer Merchant ID Password",
                        merchant_category_code: "Merchant Category Code",
                        merchant_country_code: 'Merchant Country Code',
                        merchant_name: 'Merchant Name',
                        message_category: 'Message Category',
                        message_category_option_pa: 'PA',
                        message_category_option_npa: 'NPA',
                        purchase_currency: 'Purchase Currency',
                        transaction_type: 'Transaction Type',
                        transaction_type_option_gs: 'Goods/Service Purchase',
                        transaction_type_option_ca: 'Check Acceptance',
                        transaction_type_option_af: 'Account Funding',
                        transaction_type_option_qt: 'Quasi-Cash Transaction',
                        transaction_type_option_pal: 'Prepaid Activation and Load',
                        "3ds_provider": '3DS Provider',
                        "3ds_provider_option_hitrust": 'HiTrust',
                        "3ds_protocol_version": "3DS Protocol Version",
                        hitrust_account_id: 'Hitrust Account Id',
                        hitrust_password: 'HiTrust Password',
                        hitrust_base_url: "HiTrust Base Url",
                        hitrust_mastercard_requestor_prefix: 'HiTrust Mastercard Requestor Prefix'
                    }
                },
                messages: { title: "Messages" },
                chargebacks: {
                    title: "Chargebacks",
                    chargeback: "Chargeback",
                    merchants: "Merchants",
                    providers: "Providers",
                    merchantproviders: "Merchant Providers",
                    document_file: "Document File",
                    new_chargeback: "New Chargeback",
                    chargeback_import: "Chargeback Import",
                    chargeback_details: "Chargeback Details",
                    create_merchant: "Create Merchant",
                    create_provider: "Create Provider",
                    create_merchant_provider: "Create Merchant Provider",
                    view_merchant: "View Merchant",
                    view_provider: "View Provider",
                    new_merchant: "New Merchant",
                    new_provider: "New Provider",
                    new_merchant_provider: "New Merchant Provider",
                    request_alert_credit: "Request Alert Credit",
                    chargeback_documents: "Chargeback Documents",
                    alertproviders:"Alert Providers",
                    new_alertprovider: "New Alert Provider",
                    create_alertprovider: "Create Alert Provider",
                    view_alertprovider: "View Alert Provider",
                    new_merchant_alertprovider: "New Merchant Alert Provider",
                    create_merchant_alertprovider: "Create Merchant Alert Provider",
                    view_merchant_alertprovider: "View Merchant Alert Provider",
                    merchantalertproviders: "Merchant Alert Providers",
                    form: {
                        cancel: "Cancel",
                        upload: "Upload",
                        submit: "Submit",
                        import: "Import",
                        importing: "Importing",
                        uploading: "Uploading",
                        submitting: "Submitting",
                        select_file: "Select File",
                        file_name: "File Name",
                        csv_file: "CSV File",
                        merchant: "Merchant",
                        provider: "Provider",
                        retailer: "Retailer",
                        merchant_transaction_id: "Merchant Transaction ID",
                        transaction_date: "Transaction Date",
                        card_number: "Card Number",
                        descriptor: "Descriptor",
                        amount: "Amount",
                        merchant_id: "Merchant ID",
                        arn: "ARN",
                        response_message: "Chargeback Reason",
                        download_template: "Download Template",
                        base64_cbdata: "Raw Data",
                        created_date: "Created Date",
                        name: "Name",
                        alerts: "Alerts",
                        transactionprocessing: "Transaction Processing",
                        jwt_token: "JWT Token",
                        generate_jwt: "Generate New JWT Token",
                        classfile: "Class File",
                        url: "Url",
                        apiurl: "Api Url",
                        autoimport: "Auto Import",
                        isactive: "Status",
                        password1: "Password 1",
                        password2: "Password 2",
                        password3: "Password 3",
                        chargebackReference: "Chargeback Reference",
                        alert_id: "Alert ID",
                        currency: "Currency",
                        token: "Token",
                        notify_url:"Notification Url",
                        chargeback_date:"Chargeback Date",
                        alertprovider:"Alert Provider",
                        auth1:"Auth1",
                        auth2:"Auth2",
                        auth3:"Auth3"
                    },
                    messages: {
                        upload_successful: "Upload successful.",
                        file_must_smaller: "File must smaller than 700KB!",
                        document_upload_successful: "Document upload successful.",
                        chargeback_import_successful: "Chargeback import successful.",
                        chargeback_create_successful: "Chargeback create successful.",
                        notice_1: "Please ensure the first row includes headers and not a product and that the field delimiter is set to comma.",
                    },
                    tooltip: {
                        max_upload_file_size: "Maximum upload file size",
                        alert_matched: "Alert Matched",
                        transaction_matched: "Transaction Matches",
                        require_documents_upload: "Require Documents Upload!",
                    },
                },
                alerts: {
                    title: "Alerts",
                    title_details: "Alert",
                    title_expiry: "Alerts - Expiring today",
                    status: "Status",
                    reason: "Reason",
                    alert_id: "Alert ID",
                    refunded: "Refunded",
                    not_settled: "Not Settled",
                    not_refunded: "Not Refunded",
                    update_status: "Update Outcome", //status text changed to outcome
                    alert_details: "Alert Details",
                    duplicate_alertid: "Duplicate Alert ID",
                    update_status_successful: "Outcome updated successfully",  //status text changed to outcome
                    receive_data: "Receive Data",
                    alertdate: "Alert Date",
                    sitename: "Site Name",
                    alert_type: "Alert Type",
                    transaction_date: "Transaction Date",
                    transaction_amount: "Transaction Amount",
                    transaction_currency: "Transaction Currency",
                    providername: "Provider",
                    retailername: "Retailer",
                    provider: "Provider",
                    provider_caseid: "Provider Reference",
                    cardnumber: "Card Number",
                    cardtype: "Card Type",
                    merchantname: "Merchant",
                    descriptor: "Descriptor",
                    issuername: "Issuer Name",
                    source: "Source",
                    arn: "ARN",
                    authcode: "Auth Code",
                    outcome: "Outcome",
                    reasoncode: "Code",
                    outcome_updateon: "Provider Updated On",
                    outcome_updated_on: "Outcome Updated On",
                    merchant_request: "Merchant Request",
                    merchant_transaction_id: "Merchant Transaction ID",
                    merchant_updated_on: "Merchant Update On",
                    belongtomerchant: "Belongs to Merchant",
                    chargeback_proceeded: "Chargeback Occurred",
                    is_duplicate: "Duplicate Alert",
                    alert_credits: "Alert Credits",
                    alert_credit_documents: "Alert Credit Documents",
                    merchant_updates: "Merchant Updates",
                    repost_alert: "Repost Alert",
                    request_credit: "Request Credit",
                    credit_reason: "Credit Reason",
                    add_to_batch: "Add to Credit Batch",
                    status_updates: "Status Updates",
                    comment: "Comment",
                    yes: "Yes",
                    no: "No",
                    mcc: "MCC",
                    tier: "Tier",
                    er_eligible:"ER eligible",
                    er_enabled:"ER enabled",
                    er_complete:"ER complete",
                    bin:"Bin",
                    caid:"Caid",
                    count: "Count",
                    stat_title: "Alert Statistics",
                    please_select_sid: "Please Select Site",
                    amount_refunded: "Amount Refunded",
                    chargeback_reference_number: "Chargeback Reference Number",
                    eci_code: "ECI Code",
                    tracking_number: "Tracking Number",
                    tooltip: {
                        outcome: "Last outcome status from the merchant system.",
                        outcome_updateon: "Date TxShield updated the alert provider.",
                        merchant_updated_on: "Date the merchant sent TxShield, the last update.",
                        receive_data: "This is the button to show raw data.",
                        potential_transaction_matches: "Potential transaction matches.",
                    },
                    required_messages: {
                        duplicate_alertid_required: "Please enter Duplicate Alert ID.",
                        amount_refunded_required: "Please enter amount refunded.",
                        duplicate_alertid_not_valid: "Duplicate Alert ID is not valid.",
                    },
                    dispute_condition_code: "Dispute Condition Code",
                    update_warning:"The update will be sent to the issuer. However, not every issuer accepts updates, some will only acknowledge the first status sent to them"
                },
                rdractivity: {
                    title: "RDR Activity",
                },
                profile: {
                    title: "Profile",
                    tabs: {
                        general_information: "General Information",
                        contact: "Contact",
                        group: "Group",
                        change_password: "Change Password",
                        "2fa": "2FA",
                    },
                    form: {
                        submit: "Submit",
                        username: "Username",
                        first_name: "First Name",
                        last_name: "Last Name",
                        email: "Email",
                        client_ip_restrictions: "Client IP Restrictions",
                        company: "Company",
                        position: "Position",
                        primary_contact: "Primary Contact",
                        financial_contact: "Financial Contact",
                        technical_contact: "Technical Contact",
                        support_contact: "Support Contact",
                        comment: "Comment",
                        phone: "Phone",
                        mobile: "Mobile",
                        other: "Other",
                        address: "Address",
                        city: "City",
                        state: "State",
                        postcode: "Postcode / Zipcode",
                        country: "Country",
                        old_password: "Old Password",
                        new_password: "New Password",
                        confirm_password: "Confirm Password",
                        "2fa_option": "2FA Option",
                        secret_key: "Secret Key",
                        authenticator_app: "Authenticator App",
                        user_theme: "Theme",
                        required_messages: {
                            inform_fname_required: "Please input your First Name!",
                            inform_lname_required: "Please input your Last Name!",
                            inform_email_required: "Please input your Email!",
                            changepass_old_required: "Please input your Old Password!",
                            changepass_new_required: "Please input your New Password!",
                            changepass_same_current: "Your new password cannot be the same as your current password!",
                            changepass_confirm_required: "Please input your Confirm Password!",
                            changepass_confirm_nomatch: "Confirm password does not match!",
                            changepass_new_min8: "New password must be minimum 8 characters.",
                            changepass_confirm_min8: "Confirm password must be minimum 8 characters.",
                        },
                    },
                },
                alertsforreview: {
                    title: "Alerts for Review",
                },
                oi: {
                    merchants: "TxFind - Merchants",
                    bincaids: "TxFind - BIN/CAID",
                    descriptors: "TxFind - Descriptors",
                    descriptor_details: "TxFind - Descriptor Detail",
                    cart_list: "TxFind - Carts",
                    cart_details: "TxFind - Cart Detail",
                    lookup_history: "TxFind - Lookup History",
                    new_merchant: "New Merchant",
                    create_merchant: "Create Merchant",
                    merchant_created: "Merchant Created",
                    merchant_updated: "Merchant Updated",
                    view_merchant: "View Merchant",
                    received_data: "Received Data",
                    return_data: "Return Data",
                    create_descriptor: "Create Descriptor",
                    descriptor_created: "Descriptor Created",
                    descriptor_updated: "Descriptor Updated",
                    new_descriptor: "New Descriptor",
                    new_bincaid: "New BIN/CAID",
                    edit_bincaid: "Edit BIN/CAID",
                    bincaid_added: "BIN/CAID Added",
                    bincaid_updated: "BIN/CAID Updated",
                    tabs: {
                        transaction_details: "Transaction Details",
                        customer_information: "Customer Information",
                        past_transactions: "Past Transactions",
                        product_purchased: "Product Purchased",
                        industry_details_flight_details: "Industry Details - Flight Details",
                        industry_details_hotel_details: "Industry Details - Hotel Details",
                        delivery_details: "Delivery Details",
                        physical_fulfillment: "Physical Fulfillment",
                        digital_service_delivery: "Digital Service Delivery",
                        crm_details: "CRM Details",
                        lookup_history: "Lookup History",
                        attachments: "Attachments"
                    },
                    form: {
                        name: "Name",
                        title: "Title",
                        arn: "ARN",
                        merchant_id: "Merchant ID",
                        jwt_token: "JWT Token",
                        generate_jwt: "Generate New JWT Token",
                        txshieldoicartreference: "TxFind Cart Reference",
                        lookupreference: "Lookup Reference",
                        lookupdate: "Lookup Date",
                        receiveddata: "Received Data",
                        returndata: "Return Data",
                        merchantid: "Merchant ID",
                        merchantref: "Merchant Reference",
                        paymentdescriptor: "Descriptor",
                        authorizationcode: "Auth Code",
                        authorizationdate: "Auth Date",
                        authorizationamount: "Auth Amount",
                        settlementdate: "Settlement Date",
                        settlementamount: "Settlement Amount",
                        transactiondate: "Transaction Date",
                        cardno: "Credit Card Number",
                        alerthappened: "Alert",
                        alertreference: "Alert Reference",
                        chargebackhappened: "Chargeback",
                        chargebackreference: "Chargeback Reference",
                        datecreated: "Date",
                        attachmentsid: "Attachments ID",
                        attachmentsfile: "Attachments File",
                        taxamount: "Tax",
                        billingaddressdetails: "Billing Address",
                        shippingandhandlingamount: "Shipping And Handling",
                        shippingaddressdetails: "Shipping Address",
                        totalamount: "Total",
                        dateofrefund: "Date Of Refund",
                        refundamount: "Refund",
                        refundprocessed: "Refund Processed",
                        avsresultcode: "AVS Result",
                        avschecked: "AVS Checked",
                        cvvresultcode: "CVV Result",
                        cvvchecked: "CVV Checked",
                        threedsecicode: "3DS Eci Code",
                        threedsauthenticationvalue: "3DS Authentication Value",
                        devicename: "Device Name",
                        deviceid: "Device ID",
                        devicelocation: "Device Location",
                        ipaddress: "IP Address",
                        refundpolicy: "Refund Policy",
                        termsandconditions: "Terms And Conditions",
                        customername: "Customer Name",
                        firsttransactiondate: "First Transaction Date",
                        emailaddress: "Email Address",
                        lengthofrelationship: "Length Of Relationship",
                        phonenumber: "Phone Number",
                        provider: "Provider",
                        merchant: "Merchant",
                        active: "Active",
                        inactive: "Inactive",
                        status: "Status",
                        updating: "Updating",
                        update: "Update",
                        bin: "BIN",
                        caid: "CAID",
                        referenceNumber: "Reference No.",
                        addbincaidwarning: "If BIN & CAID already exist, will instead perform update"
                    },
                    advanced_search: {
                        value_field: "Value to search",
                        txshieldoicartreference_field: "TxFind Cart Reference",
                        arn_field: "ARN",
                        cardnumber_field: "Card Number",
                        descriptor_field: "Descriptor",
                        merchant: "Merchant",
                        had_lookup: "Had Lookup",
                        alert: "Alert",
                        chargeback: "Chargeback",
                        descriptor: "Descriptor",
                        search_btn: "Search",
                        reset_btn: "Reset",
                    },
                },
                virtual_terminal: {
                    title: "Virtual Terminal",
                    processor_details: "Processor Details",
                    checkout_details: "Checkout Details",
                    additional_details: "Additional Details",
                    billing_information: "Billing Information",
                    order_information: "Order Information",
                    shipping_address: "Shipping Address",
                    merchant_defined_fields: "Merchant Defined Fields",
                    payment_related_info: "Payment Related Info",
                    payment_successful: "Payment Successful",
                    payment_failed: "Payment Failed",
                    transaction_reference: "Transaction Reference",
                    total_amount: "Total Amount",
                    process_button: "Process",
                    back_to_virtual_terminal_button: "Back to Virtual Terminal",
                    form: {
                        process: "Process",
                        item_no: "Item No.",
                        quantity: "Quantity",
                        currency: "Currency",
                        sales_tax: "Sales Tax",
                        tx_action: "TX Action",
                        item_name: "Item Name",
                        description: "Description",
                        amount_unit: "Amount/Unit",
                        affiliate_id: "Affiliate ID",
                        coupon_value: "Coupon Value",
                        shipping_cost: "Shipping Cost",
                        add_cart_item: "Add Cart Item",
                        additional_details: "Additional Details",
                        add_processing_fees: "Add Processing Fees",
                        please_select_site: "Please select site",
                        please_select_currency: "Please select currency",
                        ref_1: "Reference ID 1",
                        ref_2: "Reference ID 2",
                        ref_3: "Reference ID 3",
                        ref_4: "Reference ID 4",
                        sid: "SID",
                        wid: "WID",
                        tid: "TID",
                        card_no: "Credit Card Number",
                        expiry: "Expiry",
                        cvvcid: "CVV/CID",
                        surcharge_type: "Surcharge Type",
                        amount: "Amount",
                        surcharge: "Surcharge",
                        total: "Total",
                        firstname: "Firstname",
                        lastname: "Lastname",
                        country: "Country",
                        address1: "Address",
                        address2: "Address (cont.)",
                        city: "City",
                        state_province: "State/Province",
                        zip: "Zip Code",
                        phone: "Phone Number",
                        email: "Email Address",
                        orderid: "Order ID",
                        ponumber: "PO Number",
                        orderdesc: "Order Description",
                        shipping: "Shipping",
                        tax_exempt: "Tax Exempt",
                        sameBilling: "Same as Billing",
                        merchant_defined_field: "Merchant Defined Field",
                        routing_number: "Routing Number",
                        account_number: "Account Number",
                        amount_only_usd: "Amount (USD)",
                        account_type: "Account Type"
                    },
                },
                possibletransactionmatches: {
                    title: "Possible Transaction Matches",
                    title_matched: "Dispute Intelligence Transaction Matches",
                    transaction_info: "Transaction Info",
                    confirm: {
                        q_1: "Are you sure you want to match?",
                        notice_1: "The following changes will occur:",
                        notice_11: "We will attempt a refund for the amount of",
                        notice_12: "on Order ID",
                        notice_21: "If successful, we will update the Alert",
                        notice_22: "with the following:",
                        outcome: "Outcome: Resolved",
                        refunded: "Refund Status: Refunded",
                        reason: "Reason: Case Resolved, Credit and Cancellation Processed",
                    },
                },
                possiblerdrtransactionmatches: {
                    title: "Possible RDR Transaction Matches",
                    title_matched: "Dispute Intelligence Transaction Matches",
                    transaction_info: "Transaction Info",
                    confirm: {
                        q_1: "Are you sure you want to match?",
                        notice_1: "The following changes will occur:",
                        notice_11: "We will attempt to mark an alert for this transaction ",
                        notice_12: "in the gateway",
                        notice_21: "If successful, we will update the Alert",
                        notice_22: "with the original status of the alert"
                    },
                },
                pagenotfound: {
                    title: "Page Not Found",
                    text: "Sorry, the page you visited does not exist.",
                    button: "Back Home",
                },
                txalert_admin:{
                    enrollment_statuses:"Enrollment Statuses",
                    dispute_condition_code:"Dispute Condition Code",
                    credit_reasons:"Credit Reason List",
                    credit_statuses: "Credit Statuses",
                    mcc_tiers:"MCC Tiers",
                    alert_providers:"Alert Providers",
                    alert_types:"Alert Types",
                    alert_provider_logins:"Alert Provider Logins",
                    er_logins:"ER Gateway Logins",
                    providerpostlog:"Post to provider logs",
                    posttomerchantlog:"Post to merchant logs",
                    er_providers:"ER Providers",
                    enrollmentstatus:"Enrollment Status",
                    acquiringbanklists:"Acquiring Bank List",
                    acquiringbankaccountlist:"Acquiring Bank Account List",
                    providerpostretrylog:"Post to provider Retry logs",
                    posttomerchantretrylog:"Post to merchant Retry logs",
                    themelist:"Theme List",
                    emailtemplatelist:"Email Templates",
                    credit_requests:"Credit Requests",
                    new_credit_request:"New Credit Request",
                    add_credit_request:"Add Credit Request",
                    ertransactionmatches:"Express Resolve Matches",
                    alert_provider:"Alert Provider",
                    alertresponses:"Alert Responses",
                    ermatch_transaction:"Alert ER Transaction Match",
                    issuerbins:"Issuer Bin List",
                    import_issuerbin:"Import Issuer Bin List",
                    import_descriptor:"Import Descriptors",
                    import_bincaid:"Import Bincaid",
                    emailsendlog:"Email Send Log",
                    backgroundprocesslog:"Background Process Log",
                    expressresolvelogs:"Express Resolve Gateway Logs",
                    tabs:{
                        details:"Details",
                        descriptors:"Descriptors",
                        bincaids:"BIN/CAID",
                        ergateways:"ER gateways",
                        posttomerchant:"Post to merchant",
                        posttoprovider:"Post to provider"
                    },
                    form:{
                        new_dispute_condition_code:"New Dispute Condition Code",
                        add_dispute_condition_code:"Add Dispute Condition Code",
                        view_dispute_condition_code:"View Dispute Condition Code",
                        condition_code:"Condition Code",
                        network:"Network",
                        dispute_condition:"Dispute Condition",
                        category:"Category",
                        new_enrollstatus:"New Enrollment Status",
                        add_enrollstatus:"Add Enrollment Status",
                        view_enrollstatus:"View Enrollment Status",
                        cancel: "Cancel",
                        submit:"Submit",
                        submitting:"Submitting",
                        status:"Status",
                        isactive:"Is Active",
                        admin_only:"Admin Only",
                        new_credit_reason:"New Credit Reason",
                        add_credit_reason:"Add Credit Reason",
                        view_credit_reason:"View  Credit Reason",
                        new_creditstatus:"New Credit Status",
                        add_creditstatus:"Add Credit Status",
                        view_creditstatus:"View Credit Status",
                        new_mcc:"New MCC",
                        add_mcc:"Add MCC",
                        view_mcc:"View MCC",
                        mcc:"MCC",
                        mcc_description:"MCC Description",
                        tier:"Tier",
                        new_alert_provider:"New Alert Provider",
                        add_alert_provider:"Add Alert Provider",
                        view_alert_provider:"View Alert Provider",
                        provider_name:"Provider Name",
                        class_file:"Class File",
                        email:"Email",
                        response_expiry:"Response Expiry(hrs)",
                        new_alert_type:"New Alert Type",
                        add_alert_type:"Add Alert Type",
                        view_alert_type:"View Alert Type",
                        alert_type:"Alert Type",
                        alert_provider:"Alert Provider",
                        new_alert_provider_login:"New Alert Provider Login",
                        add_alert_provider_login:"Add Alert Provider Login",
                        view_alert_provider_login:"View Alert Provider Login",
                        path:"Folder Path",
                        url:"URL",
                        username:"Username",
                        authentication1:"Athentication1",
                        authentication2:"Athentication2",
                        authentication3:"Athentication3",
                        gateway_name:"Gateway Name",
                        gateway_reference:"Gateway Reference",
                        description:"Description",
                        new_er_provider:"New ER Provider",
                        add_er_provider:"Add ER Provider",
                        view_er_provider:"View ER Provider",
                        new_er_login:"New ER Provider Login",
                        add_er_login:"Add ER Provider Login",
                        view_er_login:"View ER Provider Login",
                        api_url:"Api URL",
                        currency:"Currency",
                        new_alert_response:"New Alert Response",
                        add_alert_response:"Add Alert Response",
                        view_alert_response:"View Alert Response",
                        status_code:"Status Code",
                        outcome:"Outcome",
                        refunded:"Refunded",
                        not_refunded:"Not Refunded",
                        not_settled:"Not Settled",
                        new_descriptor:"New Descriptor",
                        add_descriptor:"Add Descriptor",
                        view_descriptor:"View Descriptor",
                        ertransactionmatches:"ER Transaction Matches found",
                        descriptor:"Descriptor",
                        cardnumber:"Card Number",
                        transaction_date:"Transaction Date",
                        transaction_currency:"Transaction Currency",
                        transaction_amount:"Transaction Amount",
                        arn:"ARN",
                        authcode:"Auth Code",
                        disputeconditioncode:"Dispute Condition Code",
                        issuername:"Issuer Name",
                        generatetestalert:"Generate Test Alert",
                        process:"Process",
                        sent_data:"Sent Data",
                        response_data:"Response Data",
                        standard_alerts:"Standard Alerts",
                        rdr_alerts:"RDR Alerts",
                        updateenrollment:"Update Enrollment",
                        acquiring_bank:"Acquiring Bank",
                        new_acquiring_bank:"New Acquiring Bank",
                        add_acquiring_bank:"Add Acquiring Bank",
                        view_acquiring_bank:"View Acquiring Bank",
                        bank_name:"Bank Name",
                        state:"State",
                        country:"Country",
                        new_acquiring_bank_account:"New Acquiring Bank Account",
                        add_acquiring_bank_account:"Add Acquiring Bank Account",
                        view_acquiring_bank_account:"View Acquiring Bank Account",
                        bin:"Bin",
                        mid:"MID",
                        new_theme:"New Theme",
                        add_theme:"Add Theme",
                        view_theme:"View Theme",
                        from_name:"From Name",
                        from_email_address:"From Email Address",
                        theme_name:"Theme Name",
                        theme_id: "Theme ID",
                        template_id: "Template ID",
                        template_code: "Template Code",
                        new_email_template:"New Email Template",
                        add_email_template:"Add Email Template",
                        view_email_template:"View Email Template",
                        dashboard_url:"Dashboard Url",
                        statement_id:"Statement Id",
                        provider_statement_id:"Provider Statement Id",
                        invoice_number:"Invoice Number",
                        credit_status:"Credit Status",
                        received_date:"Received Date",
                        requested_date:"Requested Date",
                        status_update_date:"Status Update Date",
                        credit_reason:"Credit Reason",
                        status_reason:"Status Reason",
                        comment:"Comment",
                        merchant_id:"Merchant Id",
                        merchant_name:"Merchant Name",
                        created_date:"Created Date",
                        alert_api_url:"Alert API Url",
                        api_user_name:"API Username",
                        api_password:"API Password",
                        default_dispute_action:"Default Dispute Action",
                        alert_email:"Alert Email",
                        alert_trigger_email:"Alert Trigger Email",
                        theme:"Theme",
                        max_refund_amount:"Express Resolve Tx Maximum Value",
                        eligible_for_refund:"Eligible For Credit Requests",
                        new_credit_request:"New Credit Request",
                        add_credit_request:"Add Credit Request",
                        view_credit_request:"View Credit Request",
                        alert_provider_id:"Alert Provider Id",
                        reason:"Reason",
                        action: "Action",
                        gateway_tx_date: "Gateway Transaction Date",
                        gateway_tx_id: "Gateway Transacion Id",
                        gateway_tx_authcode:"Gateway Auth Code",
                        gateway_tx_status:"Gateway Transaction Status",
                        merchant_tx_id:"Merchant Transacion Id",
                        transaction_info:"Transaction Info",
                        match_info:"Match Score Info",
                        new_issuerbin:"New Issuer Bin",
                        add_issuerbin:"Add Issuer Bin",
                        view_issuerbin:"View Issuer Bin",
                        brand:"Brand",
                        type:"Type",
                        level:"Level",
                        isoa2:"ISO A2",
                        isoa3:"ISO A3",
                        isonumber:"ISO Number",
                        www:"WWW",
                        phone:"Phone",
                        delimiter:"Delimiter",
                        header_row_present:"Header Row Present?",
                        merchant:"Merchant",
                        provider:"Provider"
                    }
                },
                descriptor:{
                    details:"Details",
                    variations:"Variations",
                    descriptor:"Descriptor",
                    form:{
                        new_descriptor: "New Descriptor",
                        add_descriptor: "Add Descriptor",
                        view_descriptor: "View Descriptor",
                        submit:"Submit",
                        submitting:"Submitting",
                        cancel:"Cancel",
                        descriptor_id : "Descriptor ID",
                        descriptor : "Descriptor",
                        active: "Active",
                        merchant_name:"Merchant Name"
                    }
                },
                paymentlink: {
                    title: 'Payment Links',
                    title_not_found: 'No site associated with your account.',
                    new_paymentlink: 'Payment Link',
                    form: {
                        sid: "SID",
                        amount: "Amount",
                        currency: 'Currency',
                        payment_description: 'Payment Description',
                        expiry_date: 'Expire Date',
                        first_name: 'First Name',
                        last_name: 'Last Name',
                        email: 'Email',
                        phone: 'Phone',
                        address:'Address',
                        city: 'City',
                        state: 'State',
                        country: 'Country',
                        please_select_site: "Please select site",
                        please_select_currency: "Please select currency",
                        please_select_country: "Please select country",
                        cancel: 'Cancel',
                        submit: 'Submit',
                    }
                }
            },
            tables: {
                fields: {
                    id: "ID",
                    _id: "ID",
                    arn: "ARN",
                    txid: "TXID",
                    name: "Name",
                    time: "Time",
                    fees: "Fees",
                    files: "Files",
                    amount: "Amount",
                    reason: "Reason",
                    action: "Action",
                    status: "Status",
                    subject: "Subject",
                    outcome: "Outcome",
                    reserve: "Reserve",
                    message: "Message",
                    card_no: "Card No.",
                    currency: "Currency",
                    merchant: "Merchant",
                    reseller: "Reseller",
                    detail_id: "Detail ID",
                    sitename: "Site Name",
                    site_name: "Site Name",
                    rcode: "Rcode",
                    site_status: "Site Status",
                    retailer_name: "Retailer Name",
                    timestamp: "Timestamp",
                    cardnumber: "Card No.",
                    alert_type: "Alert Type",
                    alertdate: "Alert Date",
                    descriptor: "Descriptor",
                    descriptor_text: "Descriptor Text",
                    alert_descriptor: "Alert Descriptor",
                    bank_mid: "Bank MID",
                    reason_code: "Code",
                    cardno: "Card Number",
                    card_number: "Card Number",
                    created_date: "Created Date",
                    reseller_name: "Reseller",
                    site_receive: "Site Receive",
                    merchantname: "Merchant Name",
                    merchant_name: "Merchant Name",
                    descriptor_title: "Descriptor Title",
                    pay_method: "Payment Methods",
                    payment_methods: "Payment Methods",
                    transactiondate: "Transaction Date",
                    transaction_date: "Transaction Date",
                    response_message: "Response Message",
                    merchant_request: "Merchant Request",
                    merchant_response: "Merchant Response",
                    merchant_updatedate: "Merchant Update Date",
                    merchant_transaction_id: "Merchant Transaction ID",
                    matchid: "ID",
                    gwname: "Gateway",
                    alertid: "Alert ID",
                    orderid: "Order ID",
                    datecreated: "Date",
                    refundstatus: "Refund Status",
                    gw_transaction_date: "GW Transaction Date",
                    gw_transactionid: "GW Transaction ID",
                    retrieval_date:"Retrieval Date",
                    provider_caseid: "Provider Reference",
                    updated_by: "Updated by",
                    txshieldoicartreference: "TxFind Cart Reference",
                    lookupreference: "Lookup Reference",
                    lookupdate: "Lookup Date",
                    receiveddata: "Received Data",
                    returndata: "Return Data",
                    merchantid: "Merchant ID",
                    merchantref: "Merchant Reference",
                    paymentdescriptor: "Descriptor",
                    authorizationcode: "Auth Code",
                    authorizationdate: "Auth Date",
                    authorizationamount: "Auth Amount",
                    settlementdate: "Settlement Date",
                    settlementamount: "Settlement Amount",
                    dateofpurchase: "Date Of Purchase",
                    amountofpurchase: "Amount Of Purchase",
                    creditcardnumber: "Credit Card Number",
                    recurringtransaction: "Recurring Transaction",
                    productname: "Product Name",
                    productdescription: "Product Description",
                    producturl: "Product Url",
                    unitpriceamount: "Unit Price",
                    quantity: "Quantity",
                    memberrewardsnumber: "Member Rewards Number",
                    manifest: "Manifest",
                    reservationnumber: "Reservation Number",
                    reservationdate: "Reservation Date",
                    checkindate: "Check In Date",
                    checkoutdate: "Check Out Date",
                    shippingcarrierurl: "Shipping Carrier Url",
                    trackingnumber: "Tracking Number",
                    dateofshipment: "Date Of Shipment",
                    dateofdelivery: "Date Of Delivery",
                    signatureconfirmation: "Signature Confirmation",
                    proofofdelivery: "Proof Of Delivery",
                    proofofusage: "Proof Of Usage",
                    locationofusage: "Location Of Usage",
                    frequencyofusage: "Frequency Of Usage",
                    dateofinteraction: "Date Of Interaction",
                    contactname: "Contact Name",
                    notes: "Notes",
                    provider: "Provider",
                    providername: "Provider Name",
                    requested_date: "Requested Date",
                    received_date: "Received Date",
                    status_update_date:"Status Update Date",
                    comment: "Comment",
                    status_reason: "Status Reason",
                    to: "To",
                    type: "Type",
                    date: "Date",
                    keyword: "Keyword",
                    field: "Field",
                    total: "Total",
                    item_no: "Item No",
                    tx_items: "TX Items",
                    per_unit: "Per Unit",
                    description: "Description",
                    trackstatus: "Track Status",
                    trackingstatus: "Tracking Status",
                    comm_to: "Communication To",
                    comm_status: "Communication Status",
                    tx_action: "TX Action",
                    default_gateway: "Default Gateway",
                    testmethod: "Test Method",
                    mcc: "MCC",
                    bin: "BIN",
                    caid: "CAID",
                    ruleset: "Ruleset",
                    verifi: "Verifi CDRN",
                    ethoca: "Ethoca",
                    rdr: "RDR",
                    requestedDate: "Requested Date",
                    updatedDate: "Updated Date",
                    referenceNumber: "Reference No.",
                    expiry_date: "Expiry Date",
                    request: "Request",
                    response: "Response",
                    datetime: "Date Time",
                    matchscore: "Match Score",
                    alertstatus:"Alert Status",
                    card_type: "Card Type",
                    mid: "MID",
                    reasoncode: "Reason Code",
                    alerts:"Alerts",
                    transactionprocessing:"Transaction Processing",
                    classfile:"Class File",
                    apiurl:"API Url",
                    active:"Active",
                    autoimport:"Auto Import",
                    version:"Version",
                    token:"Token",
                    issued_at_date:"Issued Date",
                    alertprovider: "Alert Provider",
                    tier:"Tier",
                    alert_provider_name:"Provider Name",
                    email:"Email",
                    response_expiry:"Response Expiry(hrs)",
                    enrollment_date:"Enrollment Date",
                    path:"Folder Path",
                    url:"URL",
                    username:"Username",
                    gateway_name:"Gateway Name",
                    gateway_reference:"Gateway Reference",
                    status_code:"Status Code",
                    admin_only:"Admin Only",
                    refunded:"Refunded",
                    send_data:"Send Data",
                    response_data:"Response Data",
                    post_url:"Post Url",
                    bank_name:"Bank Name",
                    state:"State",
                    country:"Country",
                    retry_date:"Retry Date",
                    success:"Success",
                    from_name:"From Name",
                    from_email_address:"From Email Address",
                    theme_name:"Theme Name",
                    theme_id: "Theme ID",
                    template_id: "Template ID",
                    template_code: "Template Code",
                    credit_created_date:"Credit Created Date",
                    actioned:"Actioned",
                    not_actioned:"Not Actioned",
                    new:"New",
                    marked_alert:"Marked Alert",
                    refund_status:"Refund Status",
                    match_score:"Match Score",
                    gateway_tx_status:"Transaction Status",
                    gateway_tx_id:"Gateway Transaction Id",
                    brand:"Brand",
                    condition_code:"Condition Code",
                    network:"Network",
                    dispute_condition:"Dispute Condition",
                    category:"Category",
                    rdr_alerts:"RDR alerts",
                    templateid:"Template Id",
                    email_subject:"Email Subject",
                    send_to:"Send To",
                    result:"Result",
                    message_id:"Message Id",
                    process_file_name:"File Name",
                    process_name:"Process Name",
                    data:"Data",
                    balance: "Balance",
                    amount_posted: "P.Amount", 
                    payeeName:"Payee Name",
                    card_name:"Account Name",
                    tid: "TID",
                    gateway: "Gateway",
                    site: "Site",
                    sid: "SID",
                    auth_code: "Auth Code",
                    caseid: "Case ID",
                    alert_id: "Alert ID",
                    linked_provider_caseid: "Provider Reference",
                    case_event: "Case Event",
                    file_download_date: "File Download Date",
                    case_received_date: "Case Received Date",
                    payment_description: 'Payment Description',
                    link_text: 'Link',
                    first_name: 'First Name',
                    last_name: 'Last Name',
                    phone: 'Phone',
                    address: 'Address',
                    city: 'City',
                    payment_status: 'Payment Status',
                    show_delete_only: 'Show delete only',
                    delete_case_id: 'Delete Case ID',
                    delete_received_date: 'Delete Received Date',
                    contact: 'Contact',
                    remarks: 'Remarks',
                    verbose: 'Verbose Mode'
                },
                actions: {
                    ok: "OK",
                    view: "View",
                    date: "Date",
                    items: "Items",
                    reset: "Reset",
                    match: "Match",
                    update: "Update",
                    search: "Search",
                    export: "Export",
                    resolve: "Resolve",
                    download: "Download",
                    selected: "Selected",
                    clear_all: "Clear All",
                    date_range: "Date Range",
                    export_all: "Export All",
                    more_details: "More Details",
                    clear: "Clear",
                    cancel: "Cancel",
                    clear_filters: "Clear Filters",
                    export_fields: "Export Fields",
                    export_headers: "Export Headers",
                    export_settings: "Export Settings",
                    export_selected: "Export Selected",
                    view_alert_data: "View Alert Data",
                    action_required: "Action Required",
                    wip: "WIP",
                    document_list: "Documents List",
                    document_upload: "Document Upload",
                    clear_filters_sorters: "Clear Filters and Sorters",
                    hide_column: "Hide Column",
                    advanced_search: "Advanced Search",
                    export_error: "Export Error",
                    edit:"Edit",
                    mark:"Mark"
                },
                filters: "Filters",
                sorters: "Sorters",
                loading: "Loading",
                emptytext: "Filter applied, some data are hidden.",
                emptytext_email_to_3ds: "Email support to start using 3DS.",
                display: "Display",
                show_total: "{{from}}-{{to}} of {{total}} items",
            },
            outcomes: {
                ok: "Ok",
                wip: "WIP",
                other: "Other",
                error: "Error",
                cancel: "Cancel",
                pending: "Pending",
                matched: "Matched",
                declined: "Declined",
                resolved: "Resolved",
                duplicate: "Duplicate",
                processing: "Processing",
                "e-commerce": "E-commerce",
                "manual entry": "Manual entry",
                manual_receive: "Manual receive",
                merchant_receive: "Merchant receive",
                cb_proceeded: "Chargeback Proceeded",
                unactioned: "Unactioned",
                shipper_contacted:"Shipper Contacted",
                missed:"Missed",
                previously_credited:"Previously Credited",
                previously_refunded:"Previously Refunded",
                unresolved_dispute:"Unresolved Dispute",
                notfound:"Not Found",
                account_suspended:"Account Suspended",
                partially_stopped:"Partially Stopped",
                stopped:"Stopped",
                previously_received_chargeback:"Previously Received Chargeback",
                account_closed:"Account Closed",
                descriptor_does_not_belong_to:"Descriptor Does Not Belong To",
                refund_failed:"Refund Failed", 
                cancelled:"Cancelled", 
                accepted:"Accepted"
            },
            specialset: {
                other: "Other",
                matched: "Matched",
                pending: "Pending",
                declined: "Declined",
                resolved: "Resolved",
                processing: "Processing",
                update: "Update",
                "action required": "Action Required",
                refunded: "Refunded",
                not_refunded: "Not Refunded",
                refund_exists: "Refund Exists",
                cannot_be_refunded: "Cannot be refunded",
                partial_refund_exists: "Partial Refund exists",
                chargeback_exists: "Chargeback exists",
                marked_alert:"Marked Alert",
                not_marked:"Not Marked",
                void_exists: "Void Exists"
            },
            refundstatus: {
                refunded: "Refunded",
                not_refunded: "Not Refunded",
                refund_exists: "Refund Exists",
                void_exists: "Void Exists"
            },
            reason_descriptions: {
                the_case_has_been_resolved_with_the_customer_no_chargeback_is_expected_refund_is_being_processed_or_no_refund_needed: "The case has been resolved with the customer. No chargeback is expected. Refund is being processed, or no refund needed.",
                case_resolved_credit__cancellation_processed: "Case Resolved, Credit & Cancellation processed",
                case_resolved_partial_credit__cancellation_processed: "Case Resolved, Partial Credit & Cancellation processed",
                case_resolved_authorization_cancelled: "Case Resolved, Authorization Cancelled",
                account_cancelledsuspended: "Account Cancelled/Suspended",
                account_cancelled__suspended: "Account Cancelled/Suspended",
                cancellation_processed: "Cancellation processed",
                transaction_previously_credited_for_case_amount_no_balance_remaining: "Transaction Previously credited for Case Amount, no balance remaining",
                contacted_the_shipper_and_attempting_intercept_of_goods: "Contacted the shipper and attempting intercept of goods",
                duplicate_request: "Duplicate Request",
                merchant_account_closed_unable_to_process_credit: "Merchant account closed, unable to process Credit",
                transaction_previously_received_a_chargeback_no_balance_remaining: "Transaction Previously received a Chargeback, no balance remaining",
                could_not_honour_cancel_request: "Could not honour cancel request",
                matched_unable_to_stop_order_fulfilment: "Matched, unable to stop order fulfilment",
                matched_proceed_with_chargeback_for_resolution: "Matched, Proceed with Chargeback for resolution",
                unmatched_case__generalother: "Unmatched Case - General/Other",
                unmatched_case__unable_to_locate_original_transaction: "Unmatched Case - Unable to locate Original Transaction",
                request_is_outside_of_eligibility_timeframe: "Request is outside of eligibility timeframe",
                transaction_3d_secure_authenticated_successfully_verified_by_visamastercard_secure_code:
                    "Transaction 3D Secure authenticated successfully (Verified by Visa/MasterCard Secure Code)",
                work_in_progress: "Work in progress",
                error_details: "Error details",
                unable_to_process_refund: "Unable to process refund",
                card_cancelled__unable_to_process_refund: "Card cancelled - Unable to process refund",
                error_transaction_declined_transaction_was_not_originally_billable: "Error Transaction declined. Transaction was not originally billable",
                unmatched_case__descriptor_does_not_belong_to_merchant: "UnMatched Case - Descriptor does not belong to Merchant",
                case_resolved:"Case Resolved",
                transaction_previously_credited_for_case_amount:"Transaction Previously credited for Case Amount"
            },
            alert_type: {
                "confirmed fraud": "Confirmed fraud",
                "customer dispute": "Customer dispute",
                dispute: "Dispute",
                dispute_notice: "Dispute_notice",
                dispute_rdr: "Dispute_rdr",
                fraud_notice: "Fraud_notice",
            },
            required_field: "This is a required field",
            required_sid_field: "Site is a required field",
            please_select: "Please Select",
            min_1_day:"Invalid date range",
            max_3_months: "Date range should be 3 months maximum",
            today: "Today",
            last_7_days: "Last 7 Days",
            last_30_days: "Last 30 Days",
            this_month: "This Month",
        },
    },
};