module.exports = {
    tw: {
        translation: {
            login: {
                email: "電子郵件信箱",
                username: "用户名",
                password: "密碼",
                login: "登入",
                submit: "提交",
                continue: "繼續",
                forgotpassword: "忘記密碼了嗎？",
                backtologin: "返回登入畫面",
                verification: {
                    verify: "核實",
                    gotit: "知道了！",
                    verificationcode: "驗證碼",
                    entercode: "輸入Google Authenticator代碼。",
                    emailcode: 
                        "我們已將驗證碼傳送至該用户帳戶的註冊電子郵件地址。 請檢查您的電子郵件並提供以下代碼。",
                    howtosetup: "如何設定兩因素身份驗證",
                    howtosetup_title: "兩因素身份驗證設定",
                    howtosetup_text: 
                        "您必須先安裝Google身份驗證器或FreeOTP之類的身份驗證應用程式，然後才能繼續。否則，您將無法登入。",
                    noqrcode_title: "設定身份驗證應用程式",
                    noqrcode_text: "請與管理員聯絡。",
                    cantscan_click: "無法掃描？ 點這裡",
                    copied: "已複製",
                    copy_secretkey: "複製密鑰",
                    scan_qrcode_below: "掃描下方二維碼，完成後提交",
                },
                required: {
                    email: "請輸入您的電子郵件信箱！",
                    username: "請輸入您的用户名！",
                    password: "請輸入您的密碼！",
                },
                dialogs: {
                    login_error: "用户名或密碼不正確。",
                    verify_error: "驗證碼不正確。",
                    login_success: "成功登入。.",
                    reset_password: "重設密碼的請求傳送到您的帳戶電子郵件中。",
                    password_changed: "密碼已更新。",
                    groups_changed: "小組已更新。",
                    profile_changed: "個人資料已更新。",
                    catch: "出了點問題！",
                },
                session: {
                    title: "會話到期",
                    desc_1: "您將被登出",
                    desc_2: "秒。",
                    desc_3: "按一下確定繼續。",
                    expired: "會話過期",
                },
            },
            resetpassword: {
                title: "重設密碼",
            },
            header: {
                menu: {
                    profile: "個人資料",
                    logout: {
                        title: "退出",
                        question: "您確定要登出嗎？",
                        yes: "是的",
                        no: "不",
                    },
                },
                clock: "系統時間",
            },
            sidebar: {
                dashboard: "主頁面",
                merchants: "商家",
                merchant: "商家",
                txfind_bincaids: "TxFind BIN/CAID",
                bincaids: "BIN/CAID",
                descriptors: "描述符",
                descriptor: "描述符",
                alertdescriptors: "描述符",
                bincaiddescriptors: "BIN/CAID",
                payments: "付款",
                transactions: "交易",
                virtualterminal: "虛擬終端",
                'virtualterminal-cliq-cc': "虛擬終端 - Cliq CC",
                'virtualterminal-cliq-ach': "虛擬終端 - Cliq ACH",
                fraudchecks: "詐欺檢查",
                refunds: "退款",
                "3dsecure": "3DS",
                "3dsecure_microservice": "3DS 安全",
                "3dsecure_overview": "概述",
                "3dsecure_merchant": '招商',
                alerts: "警示",
                alertstats: "警示統計數據",
                messages: "訊息",
                userguide: "用户指南",
                chargebacks: "退款",
                alertsforreview: "提醒提醒",
                order_insight: "TxFind",
                carts: "購物車",
                lookup: "尋找歷史",
                providers: "供應商",
                merchant_providers: "商家供應商",
                statistics: "統計數據",
                "3dsstats": "3D 安全統計",
                alert: "警報詳情",
                chargeback: "退款",
                chargeback_merchants: "退款商家",
                chargeback_providers: "退款供應商",
                chargeback_merchant_providers: "退款商家供應商",
                oi_merchants: "TxFind商家",
                oi_carts: "TxFind購物車",
                oi_carts_lookup: "TxFind尋找歷史",
                oi_cart: "OI購物車詳情",
                txfind_merchants: "TxFind商家",
                txfind_descriptors: "TxFind 描述符",
                txfind_descriptor: "TxFind 描述符詳細信息",
                txfind_carts: "TxFind購物車",
                txfind_carts_lookup: "TxFind 尋找歷史",
                txfind_cart: "TxFind 購物車詳情",
                profile: "輪廓",
                virtual_terminal: "虛擬終端",
                alertdescriptor:"警報描述符",
                binmid:"BIN / MID",
                "3dsbinmid":"BIN / MID",
                chargeback_merchant: "否認交易退款商家",
                alertproviders:"警報提供者",
                merchantalertproviders:"商家警報提供者",
                chargeback_alertprovider_logins:"商家警報提供者",
                chargeback_alertproviders:"警報提供者",
                txalert:"警報 2.0",
                alertlist:"警報列表",
                merchantlist:"商家列表",
                bincaidlist:"BIN/CAID 列表",
                enrollmentstatuslist:"註冊狀態列表",
                disputeconditioncodelist:"爭議條件代碼列表",
                alerttypelist:"警報類型",
                admin:"行政",
                descriptorlist:"描述符列表",
                creditreasonlist:"信用原因清單",
                creditstatuslist:"信用狀況清單",
                mcctierlist:"MCC 等級列表",
                alertproviderlist:"警報提供者列表",
                alertproviderlogins:"警報提供者登入列表",
                erproviders:"ER 提供者",
                erproviderlogins:"ER 提供者登入",
                alertresponses:"警報響應",
                ertransactionmatches:"ER 匹配",
                alertdetail:"警報詳情",
                generatealert:"產生警報",
                logs:"紀錄",
                posttoalertproviderlogs:"發佈到警報提供者",
                bincaid:"BIN/CAID",
                acquiringbanklist:"收單銀行名單",
                acquiringbankaccountlist:"收單銀行帳戶列表",
                posttomerchantlogs:"傳送至商戶",
                posttoalertproviderretrylogs:"重試傳輸到警報提供者",
                posttomerchantretrylogs:"重試傳送至商戶",
                themelist:"主題",
                emailtemplatelist:"電子郵件模板",
                creditrequests:"信用請求",
                merchantdetails:"商家詳情",
                alertproviderdetail:"警報提供者詳情",
                erproviderlist:"ER 提供者",
                ertransactionmatch:"ER 交易匹配",
                issuerbinlist:"發行人 BIN 列表",
                emailsendlogs:"電子郵件傳送日誌",
                backgroundprocesslogs:"後台程序日誌",
                expressresolvelogs:"快速解決網關日誌",
                balance: "余额",
                paymentlink: '支付链接',
            },
            contents: {
                dashboard: {
                    total: "總額",
                    today: "今日",
                    alerts: "警示",
                    alert_today: "今日警報",
                    alerts_today: "今天的警報",
                    alertsfor: "警報",
                    type: "警示類型",
                    title: "主頁面",
                    "3dsecure": "3DS",
                    fraudchecks: "詐欺檢查",
                    transactions: "交易",
                    alerts_chart2: "警示-每日警示總數",
                    transaction_count: "交易計數",
                    total_revenue_collected: "總收入",
                    total_counts_of_3dsecure: "3DS 總計數",
                    total_counts_of_fraudcheck: "總數",
                    avg_ticket_value_of_transaction: "平均票券價值",
                    avg_ticket_value_of_fraudcheck: "平均票券價值",
                    expiring: "今天到期",
                    action_required: "需要採取的行動",
                    wip: "WIP",
                    alert_info: "警報訊息",
                    transaction_info: "交易資訊",
                },
                days: {
                    sun: "星期日",
                    mon: "周一",
                    tue: "周二",
                    wed: "周三",
                    thu: "周四",
                    fri: "星期五",
                    sat: "周六",
                },
                months: {
                    january: "一月",
                    february: "二月",
                    march: "三月",
                    april: "四月",
                    may: "五月",
                    june: "六月",
                    july: "七月",
                    august: "八月",
                    september: "九月",
                    october: "十月",
                    november: "十一月",
                    december: "十二月",
                },
                resellers: {
                    title: "經銷商",
                },
                retailers: {
                    title: "零售商",
                },
                sites: {
                    title: "站點",
                },
                merchants: {
                    title: "商家",
                    merchant: "商人",
                    details: "商家",
                    retailer_details: "零售商詳細信息",
                    payment: "付款",
                    "3ds": "3DS",
                    alerts: "警報",
                    settings: "設定",
                    access_records: "訪問記錄",
                    bincaid: "BIN/CAID",
                    descriptor:"描述符",
                    balance:"商家餘額",
                    form: {
                        sid: "SID",
                        name: "名稱",
                        view: "視圖",
                        rcode: "Rcode",
                        theme: "主題",
                        status: "狀態",
                        active: "活躍的",
                        remarks: "備註",
                        generate: "生成",
                        inactive: "非活動",
                        reseller: "經銷商",
                        description: "描述",
                        create_reseller: "創建經銷商",
                        txfind_merchant_id: "TxFind 商户 ID",
                        generate_new_rcode: "生成新的 Rcode",
                        please_select_retailer: "請選擇零售商",
                        please_select_site: "請選擇站點",
                        please_select: "請選擇",
                        site_url: "網站 URL(http 地址)",
                        site_rcode: "站点 Rcode",
                        associated_users: "關聯用戶",
                        stop_send_email: "停止來自 SID 的電子郵件",
                        cbreportemail: "退款報告的電子郵件地址",
                        available_balance_for_withdraw: "提款可用餘額",
                        category: "類別",
                        support_group: "支持組",
                        fraud_template: "詐騙模板",
                        skip_ccv: "跳過 CCV 檢查",
                        bin_currency: "使用 BIN 選擇支付貨幣",
                        settlement_currency: "結算貨幣",
                        allow_rebilling: "允許重新計費",
                        proceedeveniffail: "失敗時繼續處理",
                        margin_percentage: "結算保證金百分比(%)",
                        alert_on_next_approved_tranaction: "下一個批准交易的警報",
                        to_address: "收件者地址",
                        allow_refund: "允許退款",
                        allow_refund_time: "無限退款時間",
                        emaildomain: "無限退款時間未激活 Sendgrid 認證的電子郵件網域（收據電子郵件，支援電子郵件）",
                        shippingntracking: "已啟用運輸和追蹤",
                        nerver: "從不",
                        perday: "每天",
                        unlimited: "無限",
                        notactive: "不活躍",
                        days: "天數",
                        app: "應用程式",
                        brw: "瀏覽器",
                        "3ri": "3DS 請求者發起 (3RI)",
                        yes: "是的",
                        no: "不",
                        payment_transaction: "支付交易",
                        recurring_transaction: "循環交易",
                        instalment_transaction: "分期交易",
                        add_card: "添加卡片",
                        maintain_card: "維護卡",
                        cardholder_verification: "持卡人驗證作為 EMV 令牌 ID & V 的一部分",
                        not_applicable: "不適用",
                        credit: "信用",
                        debit: "轉賬",
                        gs: "商品/服務購買",
                        ca: "檢查驗收",
                        af: "帳戶資金",
                        qt: "準現金交易",
                        pal: "預付費啟動和加載",
                        paay: "Paay",
                        gpay: "Gpay",
                        hitrust: "HiTrust",
                        verbose: "詳細模式開啟",
                        client_mode: "客戶端模式",
                        enable_result: "啟用結果",
                        disable_challenge: "禁用挑戰",
                        enable3ds1_frictionless: "啟用 3ds1 無摩擦",
                        enable3DS1_only: "僅啟用 3DS 1.0",
                        requestor_name: "請求者姓名",
                        requestor_url: "請求者 URL",
                        authentication_indicator: "3DS 請求者身份驗證指示符",
                        challenge_indicator: "3DS 請求者挑戰指示器",
                        acct_type: "帳戶類型",
                        acquirer_bin: "收單銀行 BIN",
                        acquirer_mid: "收單方商戶 ID",
                        master_acquirer_bin: "萬事達卡收單銀行 BIN",
                        master_acquirer_mid: "萬事達卡收單方商家 ID",
                        jcb_acquirer_bin: "JCB 收單銀行 BIN",
                        jcb_acquirer_mid: "JCB 收單方商家 ID",
                        cup_acquirer_bin: "CUP 收單銀行 BIN",
                        cup_acquirer_mid: "CUP 收單方商家 ID",
                        amex_acquirer_bin: "AMEX 收單銀行 BIN",
                        amex_acquirer_mid: "AMEX 收單方商家 ID",
                        acquirer_mid_pwd: "收單方商戶 ID 密碼",
                        merchant_category_code: "商家類別代碼",
                        merchant_country_code: "商戶國家代碼",
                        merchant_name: "商家名稱",
                        message_category: "訊息類別",
                        purchase_currency: "購買貨幣",
                        trans_type: "交易類型",
                        skip3d2: "跳過 3D2.0 (被網關設定覆蓋)",
                        three_ds_provider: "3Ds 提供者",
                        threedsprotocolversion: "3DS 協定版本",
                        paayauth3d2: "Paay APIkey | 秘密",
                        gpay_merchant_token: "Gpay 商户 Token",
                        gpay_merchant_id: "Gpay 商户 ID",
                        gpay_base_url: "Gpay URL",
                        hitrust_account_id: "HiTrust 账户 ID",
                        hitrust_password: "HiTrust 密碼",
                        hitrust_base_url: "HiTrust 基礎網址",
                        hitrust_mastercard_prefix: "HiTrust Mastercard 請求者前綴",
                        alertapiurl: "警報 api url",
                        descriptorid: "描述符 ID",
                        username: "用户名",
                        token: "令牌",
                        default_dispute_action: "預設爭議行動",
                        alert_triggeremail: "提醒郵件？",
                        alert_email: "警報通知郵件",
                        shieldrdr_review_email: "用於快速解決審查通知的電子郵件",
                        statusupdate_url: "警報狀態更新 url",
                        match: "僅匹配",
                        match_and_refund: "匹配並退款",
                        api_username:"接口用户名",
                        api_password:"接口密碼",
                        max_refund_amount:"最高退款金額",
                        eligible_for_refund:"符合退款資格",
                        created_date:"建立日期",
                        isactive:"活躍?",
                        submit:"提交",
                        new_merchant:"新商家",
                        cancel: "取消",
                        add_merchant:"新增商家",
                        view_merchant:"查看商家",
                        enrolled_services:"註冊服務"
                    },
                },
                descriptors: {
                    title: "警報描述符",
                    details: "描述符詳細信息",
                    tabs: {
                        details: "細節",
                        enrollment: "註冊",
                        enrollment_history: "招生歷史",
                        expressresolve: "爭議情報",
                        rdr:"RDR",
                        variations:"多樣化",
                        table:{
                            status: "狀態",
                            status_reason: "狀態原因",
                            providername: "提供者",
                            requested_date: "申請日期",
                            enrollment_date: "註冊日期",
                            update_date: "更新日期",
                            effective_date: "生效日期",
                            datetime: "日期時間",
                            date: "日期",
                            gatewayname: "關",
                            gatewaydescriptor: "網關描述符",
                            terminalid: "終端 ID(MID)",
                            bin:"BIN",
                            caid:"CAID"
                        }
                    },
                    form: {
                        descriptor_id : "描述符 ID",
                        descriptor : "描述符",
                        active: "積極的",
                        site_name: "網站名稱",
                    }
                },
                bincaids: {
                    title: "BIN/CAID",
                    view_bincaid: "查看 BIN/CAID",
                    details: "細節",
                    enrollment_history: "招生歷史",
                    ruleset_history:"規則集歷史",
                    tabs:{
                        enrollment:"註冊"
                    },
                    form: {
                        bin: "Bin",
                        caid: "Caid",
                        mcc: "MCC",
                        tier:"等級",
                        vrol_profile: "VROL 簡介",
                        descriptor: "描述符",
                        request_date: "申請日期",
                        update_date: "更新日期",
                        status: "狀態",
                        status_reason: "狀態原因",
                        reference_no: "參考編號",
                        new_bincaid: "新的 Bin Caid",
                        add_bincaid: "添加 Bin Caid",
                        view_bincaid: "查看 Bin Caid",
                        submit:"提交",
                        submitting:"正在提交",
                        cancel:"取消",
                        merchant:"商家",
                        rule_set:"規則集",
                        comment:"評論",
                        id:"ID",
                        active:"活躍"
                    }
                },
                transactions: {
                    title: "交易",
                    details: "交易詳情",
                    summary: "交易概要",
                    pending_refunds: "待處理退款",
                    approved_count: "批准計數",
                    approved_sum:"核准金額",
                    declined_count:"拒絕計數",
                    declined_sum:"拒絕的金額",
                    total:"總數",
                    action:"行動",
                    tabs: {
                        log: "交易日誌",
                        tx_history: "TX 交易歷史",
                        tx_details: "TX 詳細資訊",
                        payee_details: "收款人詳細資訊",
                        tx_items: "TX 物品",
                        fraudcheck_details: "詐欺檢查詳細信息",
                        communication_details: "通訊細節",
                        tracking_details: "追蹤詳情",
                    },
                    form: {
                        transaction_log: "交易日誌",
                        card_details: "卡詳情",
                        settlement_details: "結算詳情",
                        cart_and_processing_details: "購物車和處理細節",
                        user_details: "用户詳情",
                        shipping_details: "送貨細節",
                        other: "其他",
                        fraudcheck_details: "詐欺檢查詳細信息",
                        communication_details: "通訊細節",
                        tracking_details: "追蹤詳情",
                        no_fraudcheck_information: "沒有關於此交易的詐欺檢查資訊。",
                        no_communication_information: "沒有關於本次交易的通訊信息。",
                        no_tracking_details: "沒有關於此交易的跟踪詳細信息。",
                        no_transaction_log: "此交易沒有交易記錄。",
                        card_type: "卡的種類",
                        card_name: "卡名",
                        card_no: "卡號",
                        card_expiry: "卡到期",
                        start_date: "開始日期",
                        email_address: "電子郵件地址",
                        ip_address: "IP地址",
                        auth_code: "授權碼",
                        account_type: "帳戶類型",
                        account_name: "帳戶名稱",
                        account_no: "帳號",
                        routing_no: "路由號碼",
                        purse_no: "錢包號碼",
                        arn: "收單行編號",
                        settlement_amount: "總結算金額",
                        reserve: "預訂",
                        fees: "費用",
                        sitereceive: "應付給商戶",
                        cartmargin: "購物車保證金",
                        margin: "利潤",
                        currbalance: "當前餘額",
                        origtotal: "總金額",
                        origpurch: "支付金額",
                        origship: "運費金額",
                        origtax: "稅額",
                        origcoupon: "優惠券金額",
                        processedamount: "處理金額",
                        firstname: "名",
                        lastname: "姓",
                        phoneno: "電話號碼",
                        mobileno: "手機號碼",
                        address: "地址",
                        suburb: "城市/郊區",
                        state: "狀態",
                        postcode: "郵遞區號",
                        country: "國家",
                        tid: "商家參考 (TID)",
                        wid: "操作員 ID (WID)",
                        info: "附加資訊",
                        ipay: "支付寶信息",
                        ref1: "參考 1",
                        ref2: "參考 2",
                        ref3: "參考 3",
                        ref4: "參考 4",
                        affiliateid: "會員編號",
                        threat_level_assessment: "威脅等級評估",
                        rule: "規則",
                        error: "錯誤",
                        response: "回覆",
                        generates: "生成",
                    },
                },
                fraudchecks: { title: "詐欺檢查" },
                refunds: { title: "退款" },
                "3dsecure": {
                    title: "3D安全 - 交易",
                    details: "3D安全 - 細節",
                    create_binmid: "創建新的 BIN / MID",
                    view_binmid: "查看 BIN / MID",
                    imports_binmid: "輸入 BIN / MID",
                    tabs: {
                        "3DS_details": "3DS 詳細資訊",
                    },
                    binmid: {
                        title: "BIN / MID",
                        binmidlist: "BIN / MID 清單",
                        new_binmid: "新的 BinMid",
                        added: "BINMID 添加成功",
                        updated: "BINMID 已經更新"
                    },
                    merchant: {
                        title: '3DS商家',
                        merchantlist: "3DS商户名单",
                        new_merchant: '新 3DS 商家',
                    },
                    form: {
                        "3ds_authorization_details": "3DS 授權詳情",
                        "3ds_log_details": "3DS 日誌詳細信息",
                        "3DS20": "3DS2.0",
                        acctNumber: "卡號",
                        purchaseAmount: "數量",
                        threeDSIntegratorOid: "ThreeDS 積分器 Oid",
                        threeDSRequestorOrderID: "ThreeDS 請求者 OrderID",
                        threeDSRequestorData: "ThreeDS 請求者數據",
                        threeDSRequestorID: "ThreeDS 請求者 ID",
                        threeDSServerTransID: "ThreeDS 伺服器傳輸 ID",
                        acsTransID: "ACS 反式 ID",
                        dsTransID: "DS 反式 ID",
                        transStatus: "交易狀態",
                        transStatusReason: "交易狀態原因",
                        authenticationValue: "認證值",
                        authenticationAlgorithm: "認證算法",
                        eci: "ECI",
                        version: "版本",
                        errCode: "錯誤代碼",
                        errMsg: "錯誤訊息",
                        shield_threeds_ref_diaplay: "Paywiser 3DS 参考",
                        shield_threeds_merchant_ref_display: "商户参考",
                        shield_threeds_provider_ref_display: "3DS 提供程序参考",
                        shield_threeds_ptxid_display: "Paywiser 3DS PTXID",
                        shield_threeds_pay_ptxid_display: "Paywiser 支付 PTXID",
                        threeDSShieldInitLog: "傳送日誌數據",
                        threeDSShieldNotifyLog: "傳回日誌數據",
                        binmidID: "BINMID ID",
                        bin: "BIN",
                        mid: "MID",
                        mcc: "MCC",
                        card_type: "Card Type",
                        status: "狀態",
                        date_added: "新增日期",
                        site_name: "網站名",
                        cancel: "取消",
                        submit: "提交",
                        submitting: "正在提交",
                        please_select: "請選擇"
                    },
                },
                _3dsecure_overview: {
                    title: "3D安全概述",
                    filter_by_site: "按網站過濾",
                    filter_by_status: "按狀態過濾",
                    pass: "經過",
                    fail_others: "失敗（其他）",
                    no_data: "沒有數據",
                    today: "今天",
                    last7DaysPerHour: "過去 7 天每小時",
                    last7DaysPerDay: "每天過去 7 天",
                    last30DaysPerDay: "每天過去 30 天",
                },
                _3dsecure_stats: {
                    title: "3D安全統計",
                    please_select_sid: "請選擇網站",
                    please_select_report_type: "請選擇報告類型",
                    short_status: "狀態",
                    short_reason: "原因",
                    short_status_and_reason: "狀態和原因",
                    count: "計數",
                    explanation: "解釋",
                    status: "按狀態細分",
                    reason: "按原因細分",
                    status_and_reason: "按狀態和原因細分",
                    other: '其他',
                    status_total: '全部的',
                    status_explanation: {
                        y: "認證成功",
                        a: "未經身份驗證，但提供了嘗試身份驗證的證明",
                        c: "需要挑戰； 需要額外的身份驗證。 僅在禁用挑戰時返回（無摩擦）",
                        u: "無法執行身份驗證/帳戶驗證",
                        r: "身份驗證被拒絕； 發行人拒絕身份驗證並要求不嘗試授權",
                        n: "未通過身份驗證，交易被拒絕",
                        total: "所有狀態的總交易量"
                    },
                    status_reason_explanation: {
                        "1": "卡認證失敗",
                        "2": "未知設備",
                        "3": "不支援的設備",
                        "4": "超過認證頻率限制",
                        "5": "過期卡",
                        "6": "無效卡號",
                        "7": "無效交易",
                        "8": "無卡片記錄",
                        "9": "安全故障",
                        "10": "被盜卡",
                        "11": "涉嫌詐欺",
                        "12": "不允許持卡人進行交易",
                        "13": "持卡人未註冊服務",
                        "14": "ACS 事務逾時",
                        "15": "信心不足",
                        "16": "中等信心",
                        "17": "高度自信",
                        "18": "非常高的信心",
                        "19": "超過 ACS 最大挑戰",
                        "20": "不支援不付款交易",
                        "21": "不支援 3RI 交易",
                        other: "意外原因"
                    }
                },
                _3dsecure_merchant: {
                    new_merchant: '新 3DS 商家',
                    edit_merchant: '编辑 3DS 商家',
                    form: {
                        merchant_detail: "商户详情",
                        site_detail: "站点详情",
                        "3ds_setting": "3DS 设置",
                        name: "姓名",
                        description: "描述",
                        remarks: "评论",
                        contact: "接触",
                        status: '地位',
                        status_option_active: '积极的',
                        status_option_deactivated: '已停用',
                        provider: '提供者',
                        site_currency: '货币',
                        site_url: 'Url',
                        verbose_mode: '详细模式开启',
                        client_mode: '客户端模式',
                        client_mode_option_app: '应用程序',
                        client_mode_option_brw: '浏览器',
                        client_mode_option_3ri: '3DS 请求者发起 (3RI)',
                        enable_result: '启用结果',
                        enable_result_option_y: "是的",
                        enable_result_option_n: "不",
                        disable_challenge: '禁用挑战',
                        disable_challenge_option_y: "是的",
                        disable_challenge_option_n: "不",
                        enable_3ds1_friction_less: "启用 3DS 1.0 无摩擦",
                        enable_3ds1_friction_less_option_y: "是的",
                        enable_3ds1_friction_less_option_n: "不",
                        enable_3ds1_only: "仅启用 3DS 1.0",
                        enable_3ds1_only_option_y: "是的",
                        enable_3ds1_only_option_n: "不",
                        requestor_name: '请求者姓名',
                        requestor_url: '请求者网址',
                        "3DS_requestor_authentication_indicator": "3DS 请求者身份验证指示器",
                        "3DS_requestor_authentication_indicator_option_payment_transaction": "支付交易",
                        "3DS_requestor_authentication_indicator_option_recurring_transaction": "经常性交易",
                        "3DS_requestor_authentication_indicator_option_installment_transaction": "分期交易",
                        "3DS_requestor_authentication_indicator_option_add_card": "添加卡",
                        "3DS_requestor_authentication_indicator_option_maintain_card": "维护卡",
                        "3DS_requestor_authentication_indicator_option_cardholder_verification": "持卡人验证作为 EMV 令牌 ID&V 的一部分",
                        "3DS_requestor_challenge_indicator": "3DS 请求的挑战指示器",
                        "3DS_requestor_challenge_indicator_option_payment_transaction": "支付交易",
                        "3DS_requestor_challenge_indicator_option_recurring_transaction": "经常性交易",
                        "3DS_requestor_challenge_indicator_option_installment_transaction": "分期交易",
                        "3DS_requestor_challenge_indicator_option_add_card": "添加卡",
                        acct_type: "账户类型",
                        acct_type_option_not_applicable: "不适用",
                        acct_type_option_credit: "信用",
                        acct_type_option_debit: "借方",
                        visa_acquiring_bank_bin: "签证受理银行Bin",
                        visa_acquirer_merchant_id: "Visa 收单机构商户 ID",
                        mastercard_acquiring_bank_bin: "万事达卡收单银行 BIN",
                        mastercard_acquirer_merchant_id: "万事达卡收单机构商户 ID",
                        jcb_acquiring_bank_bin: "JCB 收单银行 Bin",
                        jcb_acquirer_merchant_id: "JCB 收单机构商户 ID",
                        cup_acquiring_bank_bin: "银联收单银行BIN",
                        cup_acquirer_merchant_id: "银联收单机构商户ID",
                        amex_acquiring_bank_bin: "美国运通收单银行 Bin",
                        amex_acquirer_merchant_id: "AMEX 收单机构商户 ID",
                        acquirer_merchant_id_password: "收单机构商户 ID 密码",
                        merchant_category_code: "商户类别代码",
                        merchant_country_code: '商户国家代码',
                        merchant_name: '商户名称',
                        message_category: '留言类别',
                        message_category_option_pa: 'PA',
                        message_category_option_npa: 'NPA',
                        purchase_currency: '购买货币',
                        transaction_type: '交易类型',
                        transaction_type_option_gs: '商品/服务购买',
                        transaction_type_option_ca: '检查验收',
                        transaction_type_option_af: '账户资金',
                        transaction_type_option_qt: '准现金交易',
                        transaction_type_option_pal: '预付费激活和加载',
                        "3ds_provider": '3DS 提供商',
                        "3ds_provider_option_hitrust": 'HiTrust',
                        "3ds_protocol_version": "3DS协议版本",
                        hitrust_account_id: 'Hitrust 帐户 ID',
                        hitrust_password: '高信任密码',
                        hitrust_base_url: "HiTrust 基本 URL",
                        hitrust_mastercard_requestor_prefix: 'HiTrust 万事达卡请求者前缀'
                    }
                },
                messages: { title: "訊息" },
                chargebacks: {
                    title: "退款",
                    chargeback: "退款",
                    merchants: "商户",
                    providers: "供應商",
                    merchantproviders: "商戶供應商",
                    document_file: "文件檔案",
                    new_chargeback: "新的退款",
                    chargeback_import: "拒付匯入",
                    chargeback_details: "退款詳情",
                    create_merchant: "建立商家",
                    create_provider: "創建提供者",
                    create_merchant_provider: "創建商戶提供者",
                    view_merchant: "查看商家",
                    view_provider: "查看提供者",
                    new_merchant: "新商人",
                    new_provider: "新供應商",
                    new_merchant_provider: "新商戶供應商",
                    request_alert_credit: "請求警報信用",
                    chargeback_documents: "退單文件",
                    alertproviders:"警報提供者",
                    new_alertprovider: "新警報提供者",
                    create_alertprovider: "建立警報提供者",
                    view_alertprovider: "查看警報提供者",
                    new_merchant_alertprovider: "新商家警報提供者",
                    create_merchant_alertprovider: "建立商家警報提供者",
                    view_merchant_alertprovider: "查看商家警報提供者",
                    merchantalertproviders: "商家警報提供者",
                    form: {
                        cancel: "取消",
                        upload: "上載",
                        submit: "提交",
                        import: "輸入",
                        importing: "輸入中",
                        uploading: "上載中",
                        submitting: "提交中",
                        select_file: "選擇文件",
                        file_name: "文件名稱",
                        csv_file: "CSV文件",
                        merchant: "商家名稱",
                        provider: "提供者",
                        retailer: "零售商",
                        merchant_transaction_id: "商家交易編號",
                        transaction_date: "交易日期",
                        card_number: "卡號",
                        descriptor: "描述符",
                        amount: "數量",
                        merchant_id: "商家編號",
                        arn: "收單行編號",
                        response_message: "回應訊息",
                        download_template: "下載範本",
                        base64_cbdata: "原始數據",
                        created_date: "建立日期",
                        name: "姓名",
                        alerts: "警報",
                        transactionprocessing: "事務處理",
                        jwt_token: "JWT 令牌",
                        generate_jwt: "生成新的 JWT 令牌",
                        classfile: "類別文件",
                        url: "Url",
                        apiurl: "Api Url",
                        autoimport: "自動導入",
                        isactive: "活躍",
                        password1: "密碼 1",
                        password2: "密碼 2",
                        password3: "密碼 3",
                        chargebackReference: "退款參考",
                        alert_id: "警示ID",
                        currency: "貨幣",
                        token: "代幣",
                        notify_url:"通知網址",
                        chargeback_date:"退款日期",
                        alertprovider:"警報提供者",
                        auth1:"Auth1",
                        auth2:"Auth2",
                        auth3:"Auth3"
                    },
                    messages: {
                        upload_successful: "上傳成功",
                        file_must_smaller: "檔案必須小於700KB",
                        document_upload_successful: "文件上傳成功",
                        chargeback_import_successful: "退款導入成功.",
                        chargeback_create_successful: "退款創建成功.",
                        notice_1: "請確保第一行包含標題而不是產品，並且字段分隔符號設置為逗號",
                    },
                    tooltip: {
                        max_upload_file_size: "最大上傳檔案大小",
                        alert_matched: "警報匹配",
                        transaction_matched: "交易匹配",
                        required_documents_upload: "需要文件上傳！",
                    },
                },
                alerts: {
                    title: "警示",
                    title_details: "警示",
                    title_expiry: "警示 - 今天到期",
                    status: "狀態",
                    reason: "原因",
                    alert_id: "警示ID",
                    refunded: "已退款",
                    not_settled: "未解決",
                    not_refunded: "不退款",
                    update_status: "更新狀態",
                    alert_details: "警示詳細訊息",
                    duplicate_alertid: "重複的警示ID",
                    update_status_successful: "更新狀態成功",
                    receive_data: "接收數據",
                    alertdate: "提醒日期",
                    sitename: "網站名稱",
                    alert_type: "警示類型",
                    transaction_date: "交易日期",
                    transaction_amount: "交易金額",
                    transaction_currency: "交易貨幣",
                    providername: "提供商",
                    retailername: "零售商名稱",
                    provider: "提供商",
                    provider_caseid: "提供商參數",
                    cardnumber: "卡號",
                    cardtype: "Card Type",
                    merchantname: "商家",
                    descriptor: "描述符",
                    issuername: "發卡行名稱",
                    source: "來源",
                    arn: "收單行編號",
                    authcode: "授權碼",
                    outcome: "結果",
                    reasoncode: "Code",
                    outcome_updateon: "結果更新日期",
                    outcome_updated_on: "結果更新於",
                    merchant_request: "商家請求",
                    merchant_transaction_id: "商户交易ID",
                    merchant_updated_on: "商家更新",
                    belongtomerchant: "屬於商戶",
                    chargeback_proceeded: "發生了拒付",
                    is_duplicate: "是重複的",
                    alert_credits: "警報信用",
                    alert_credit_documents: "提醒信用文件",
                    merchant_updates: "商家更新",
                    repost_alert: "重新發布警報",
                    request_credit: "請求信用",
                    credit_reason: "信用原因",
                    add_to_batch: "新增到信用批次",
                    status_updates: "狀態更新",
                    comment: "评论",
                    yes: "是的",
                    no: "不是",
                    mcc: "MCC",
                    tier:"等級",
                    er_eligible:"ER 合格的",
                    er_enabled:"ER 已啟用",
                    er_complete:"ER 完成",
                    bin:"Bin",
                    caid:"Caid",
                    count: "数量",
                    stat_title: "警報統計",
                    please_select_sid: "請選擇站點",
                    tooltip: {
                        outcome: "來自商家系統的最後結果狀態。",
                        outcome_updateon: "日期Paywiser更新了警示提供者。",
                        merchant_updated_on: "商家發送Paywiser的最新日期。",
                        receive_data: "這是顯示原始資料的按鈕。",
                        potential_transaction_matches: "潛在交易匹配",
                    },
                    required_messages: {
                        duplicate_alertid_required: "請輸入重複警示ID。",
                        duplicate_alertid_not_valid: "重複的警示ID無效。",
                    },
                    dispute_condition_code:"爭議條件代碼",
                    update_warning:"更新將發送給發行人。但是，並非每個發行人都接受更新，有些發行人只會確認發送給他們的第一個狀態"
                },
                rdractivity: {
                    title: "RDR 活動",
                },
                profile: {
                    title: "個人資料",
                    tabs: {
                        general_information: "一般資訊",
                        contact: "聯繫",
                        group: "小組",
                        change_password: "更改密碼",
                        "2fa": "2FA",
                    },
                    form: {
                        submit: "提交",
                        username: "用户名",
                        first_name: "名",
                        last_name: "姓",
                        email: "電子郵件信箱",
                        client_ip_restrictions: "客戶端IP限制",
                        company: "公司",
                        position: "職位",
                        primary_contact: "主要聯絡人",
                        financial_contact: "財務聯絡人",
                        technical_contact: "技術聯絡人",
                        support_contact: "客服聯繫人",
                        comment: "註解",
                        phone: "電話",
                        mobile: "手機",
                        other: "其他",
                        address: "地址",
                        city: "城市",
                        state: "省份/州",
                        postcode: "郵編",
                        country: "国家",
                        old_password: "舊密碼",
                        new_password: "新密碼",
                        confirm_password: "確認密碼",
                        "2fa_option": "2FA 選項",
                        secret_key: "金鑰",
                        authenticator_app: "身份驗證器應用程式",
                        user_theme: "主題皮膚",
                        required_messages: {
                            inform_fname_required: "請輸入您的名字！",
                            inform_lname_required: "請輸入您的姓氏！",
                            inform_email_required: "請輸入您的電子郵件信箱！",
                            changepass_old_required: "請輸入您的舊密碼！",
                            changepass_new_required: "請輸入您的新密碼！",
                            changepass_same_current: "您的新密碼不能與您目前的密碼相同！",
                            changepass_confirm_required: "請輸入您的確認密碼！",
                            changepass_confirm_nomatch: "確認密碼不正確！",
                            changepass_new_min8: "新密碼必須至少包含8個字符。",
                            changepass_confirm_min8: "確認密碼必須至少為8個字符。",
                        },
                    },
                },
                alertsforreview: {
                    title: "提醒提醒",
                },
                oi: {
                    merchants: "TxFind - 商家",
                    bincaids: "TxFind - BIN/CAID",
                    descriptors: "TxFind - 描述符",
                    descriptor_details: "TxFind - 描述符詳細信息",
                    cart_list: "TxFind - 購物車",
                    cart_details: "TxFind - 購物車詳情",
                    lookup_history: "TxFind - 尋找歷史",
                    new_merchant: "新商人",
                    create_merchant: "創建商家",
                    merchant_created: "商家創建",
                    merchant_updated: "商家更新",
                    view_merchant: "查看商家",
                    received_data: "接收數據",
                    return_data: "返回數據",
                    create_descriptor: "創建描述符",
                    descriptor_created: "已創建描述符",
                    descriptor_updated: "描述符已更新",
                    new_descriptor: "新描述符",
                    new_bincaid: "新的 BIN/CAID",
                    edit_bincaid: "編輯 BIN/CAID",
                    bincaid_added: "添加了 BIN/CAID",
                    bincaid_updated: "BIN/CAID 更新",
                    tabs: {
                        transaction_details: "交易明細",
                        customer_information: "客戶資料",
                        past_transactions: "過往交易",
                        product_purchased: "購買的產品",
                        industry_details_flight_details: "行業詳情 - 航班詳情",
                        industry_details_hotel_details: "行業詳情 - 飯店詳情",
                        delivery_details: "交貨詳情",
                        physical_fulfillment: "物流履行",
                        digital_service_delivery: "數位服務交付",
                        crm_details: "客戶關係管理詳情",
                        lookup_history: "尋找歷史",
                        attachments: "附件"
                    },
                    form: {
                        name: "名稱",
                        title: "標題",
                        arn: "ARN",
                        merchant_id: "商家編號",
                        jwt_token: "JWT 令牌",
                        generate_jwt: "生成新的 JWT 令牌",
                        txshieldoicartreference: "TxFind 購物車參考",
                        lookupreference: "尋找參考",
                        lookupdate: "查閱日期",
                        receiveddata: "接收數據",
                        returndata: "返回數據",
                        merchantid: "商家編號",
                        merchantref: "商家參考",
                        paymentdescriptor: "描述符",
                        authorizationcode: "授權碼",
                        authorizationdate: "授權日期",
                        authorizationamount: "授權金額",
                        settlementdate: "結算日期",
                        settlementamount: "結算金額",
                        transactiondate: "交易日期",
                        cardno: "信用卡號碼",
                        alerthappened: "警報",
                        alertreference: "警報參考",
                        chargebackhappened: "退款",
                        chargebackreference: "退款參考",
                        datecreated: "日期",
                        attachmentsid: "附件 ID",
                        attachmentsfile: "附件文件",
                        taxamount: "稅",
                        billingaddressdetails: "帳單地址",
                        shippingandhandlingamount: "運費和手續費",
                        shippingaddressdetails: "送貨地址",
                        totalamount: "全部的",
                        dateofrefund: "退款日期",
                        refundamount: "退款",
                        refundprocessed: "退款已處理",
                        avsresultcode: "AVS結果",
                        avschecked: "AVS檢查",
                        cvvresultcode: "CVV結果",
                        cvvchecked: "CVV檢查",
                        threedsecicode: "3DS Eci 程式碼",
                        threedsauthenticationvalue: "3DS 認證值",
                        devicename: "設備名稱",
                        deviceid: "設備編號",
                        devicelocation: "設備位置",
                        ipaddress: "IP地址",
                        refundpolicy: "退款政策",
                        termsandconditions: "條款與條件",
                        customername: "顧客姓名",
                        firsttransactiondate: "首次交易日期",
                        emailaddress: "電子郵件地址",
                        lengthofrelationship: "關係長度",
                        phonenumber: "電話號碼",
                        provider: "提供者",
                        merchant: "商人",
                        active: "活躍",
                        inactive: "不活躍",
                        status: "狀態",
                        updating: "進行中",
                        update: "更新",
                        bin: "BIN",
                        caid: "CAID",
                        referenceNumber: "參考編號。",
                        addbincaidwarning: "如果 BIN & CAID 已經存在，將改為執行更新"
                    },
                    advanced_search: {
                        value_field: "搜尋值",
                        txshieldoicartreference_field: "TxFind 購物車參考",
                        arn_field: "ARN",
                        cardnumber_field: "卡號",
                        descriptor_field: "描述符",
                        merchant: "商家",
                        had_lookup: "尋找",
                        alert: "警報",
                        chargeback: "退款",
                        descriptor: "描述符",
                        search_btn: "搜尋",
                        reset_btn: "重置",
                    },
                },
                virtual_terminal: {
                    title: "虛擬終端",
                    processor_details: "處理器詳細信息",
                    checkout_details: "結帳詳情",
                    additional_details: "額外細節",
                    billing_information: "帳單資訊",
                    order_information: "訂單資訊",
                    shipping_address: "收件地址",
                    merchant_defined_fields: "商家定義字段",
                    payment_related_info: "支付相關訊息",
                    payment_successful: "支付成功",
                    payment_failed: "支付失敗",
                    transaction_reference: "交易參考",
                    total_amount: "總金額",
                    process_button: "處理",
                    back_to_virtual_terminal_button: "返回虛擬終端",
                    form: {
                        process: "處理",
                        item_no: "項目編號",
                        quantity: "數量",
                        currency: "貨幣",
                        sales_tax: "銷售稅",
                        tx_action: "發送動作",
                        item_name: "項目名稱",
                        description: "描述",
                        amount_unit: "金額/單位",
                        affiliate_id: "會員編號",
                        coupon_value: "優惠券價值",
                        shipping_cost: "運輸費",
                        add_cart_item: "新增購物車項目",
                        additional_details: "額外細節",
                        add_processing_fees: "新增處理費",
                        please_select_site: "請選擇網站",
                        please_select_currency: "請選擇貨幣",
                        ref_1: "參考編號 1",
                        ref_2: "參考編號 2",
                        ref_3: "參考編號 3",
                        ref_4: "參考編號 4",
                        sid: "SID",
                        wid: "WID",
                        tid: "TID",
                        card_no: "信用卡號",
                        expiry: "到期",
                        cvvcid: "CVV/CID",
                        surcharge_type: "附加費類型",
                        amount: "金額",
                        surcharge: "附加費",
                        total: "全部的",
                        firstname: "名",
                        lastname: "姓",
                        country: "国家",
                        address1: "地址",
                        address2: "地址（續）",
                        city: "城市",
                        state_province: "州/省",
                        zip: "郵遞區號",
                        phone: "電話號碼",
                        email: "電子郵件地址",
                        orderid: "訂單編號",
                        ponumber: "訂單號",
                        orderdesc: "訂單說明",
                        shipping: "船運",
                        tax_exempt: "免稅",
                        sameBilling: "同開票",
                        merchant_defined_field: "商戶定義字段",
                        routing_number: "路由號碼",
                        account_number: "帳號",
                        amount_only_usd: "金額（USD）",
                        account_type: "帳戶類型"
                    },
                },
                possibletransactionmatches: {
                    title: "可能的交易匹配",
                    title_matched: "爭議情報交易匹配",
                    transaction_info: "交易資訊",
                    confirm: {
                        q_1: "您確定要匹配嗎？",
                        notice_1: "將會發生以下變化：",
                        notice_11: "我們將嘗試退款",
                        notice_12: "訂單 ID",
                        notice_21: "如果成功，我們將更新警報",
                        notice_22: "有以下內容：",
                        outcome: "結果：已解決",
                        refunded: "退款狀態：已退款",
                        reason: "原因：案例已解決，信用和取消已處理",
                    },
                },
                possiblerdrtransactionmatches: {
                    title: "可能的 RDR 交易匹配",
                    title_matched: "爭議情報交易匹配",
                    transaction_info: "交易資訊",
                    confirm: {
                        q_1: "您確定要匹配嗎?",
                        notice_1: "將會發生以下變化:",
                        notice_11: "我們將嘗試對此交易標記警報",
                        notice_12: "在網關中",
                        notice_21: "如果成功，我們將更新警報",
                        notice_22: "與警報的原始狀態"
                    },
                },
                pagenotfound: {
                    title: "找不到網頁",
                    text: "抱歉，您造訪的頁面不存在",
                    button: "回首頁",
                },
                txalert_admin:{
                    enrollment_statuses:"註冊狀態",
                    dispute_condition_code:"爭議條件代碼",
                    credit_reasons:"信用原因清單",
                    credit_statuses: "信用狀況",
                    mcc_tiers:"MCC 等級",
                    alert_providers:"警報提供者",
                    alert_types:"警報類型",
                    alert_provider_logins:"警報提供者登入",
                    er_logins:"ER 網關登入",
                    providerpostlog:"傳輸到提供者日誌",
                    posttomerchantlog:"傳輸至商戶日誌",
                    er_providers:"ER 供应商",
                    enrollmentstatus:"註冊狀況",
                    acquiringbanklists:"收單銀行名單",
                    acquiringbankaccountlist:"收單銀行帳戶列表",
                    providerpostretrylog:"傳輸到提供者重試日誌",
                    posttomerchantretrylog:"傳輸至商家重試日誌",
                    themelist:"主題列表",
                    emailtemplatelist:"電子郵件模板",
                    credit_requests:"信用請求",
                    new_credit_request:"新的信用請求",
                    add_credit_request:"新增信用請求",
                    ertransactionmatches:"快速解決匹配",
                    alert_provider:"警報提供者",
                    alertresponses:"警報響應",
                    ermatch_transaction:"警報 ER 交易匹配",
                    issuerbins:"發行人 Bin 列表",
                    import_issuerbin:"導入發行人 Bin 列表",
                    import_descriptor:"導入描述符",
                    import_bincaid:"導入 BIN/CAID",
                    emailsendlog:"電子郵件傳送日誌",
                    backgroundprocesslog:"後台程序日誌",
                    expressresolvelogs:"快速解決網關日誌",
                    tabs:{
                        details:"細節",
                        descriptors:"描述符",
                        bincaids:"BIN/CAID",
                        ergateways:"ER 網關",
                        posttomerchant:"傳輸至商家",
                        posttoprovider:"傳輸到提供者"
                    },
                    form:{
                        new_dispute_condition_code:"新爭議條件代碼",
                        add_dispute_condition_code:"新增爭議條件代碼",
                        view_dispute_condition_code:"查看爭議條件代碼",
                        condition_code:"條件代碼",
                        network:"網路",
                        dispute_condition:"爭議條件",
                        category:"類別",
                        new_enrollstatus:"新註冊狀態",
                        add_enrollstatus:"新增註冊狀態",
                        view_enrollstatus:"查看註冊狀態",
                        cancel: "取消",
                        submit:"提交",
                        submitting:"正在提交",
                        status:"狀態",
                        isactive:"活躍的",
                        admin_only:"限管理員",
                        new_credit_reason:"新信用原因",
                        add_credit_reason:"添加信用原因",
                        view_credit_reason:"查看信用原因",
                        new_creditstatus:"新信用狀態",
                        add_creditstatus:"添加信用狀態",
                        view_creditstatus:"查看信用狀態",
                        new_mcc:"新 MCC",
                        add_mcc:"添加 MCC",
                        view_mcc:"查看 MCC",
                        mcc:"MCC",
                        mcc_description:"MCC 描述",
                        tier:"等級",
                        new_alert_provider:"新警報提供者",
                        add_alert_provider:"新增警報提供者",
                        view_alert_provider:"查看警報提供者",
                        provider_name:"提供者名稱",
                        class_file:"類別文件",
                        email:"電子郵件",
                        response_expiry:"響應到期(小時)",
                        new_alert_type:"新警報類型",
                        add_alert_type:"新增警報類型",
                        view_alert_type:"查看警報類型",
                        alert_type:"警報類型",
                        alert_provider:"警報提供者",
                        new_alert_provider_login:"新警報提供者登入",
                        add_alert_provider_login:"新增警報提供者登入",
                        view_alert_provider_login:"查看警報提供者登入",
                        path:"資料夾路徑",
                        url:"URL",
                        username:"用户名",
                        authentication1:"驗證1",
                        authentication2:"驗證2",
                        authentication3:"驗證3",
                        gateway_name:"網關名稱",
                        gateway_reference:"網關參考",
                        description:"描述",
                        new_er_provider:"新 ER 提供者",
                        add_er_provider:"新增 ER 提供者",
                        view_er_provider:"查看 ER 提供者",
                        new_er_login:"新 ER 提供者登入",
                        add_er_login:"新增 ER 提供者登入",
                        view_er_login:"查看 ER 提供者登入",
                        api_url:"Api URL",
                        currency:"貨幣",
                        new_alert_response:"新警報響應",
                        add_alert_response:"新增警報響應",
                        view_alert_response:"查看警報響應",
                        status_code:"狀態碼",
                        outcome:"結果",
                        refunded:"已退款",
                        not_refunded:"未退款",
                        not_settled:"未解決",
                        new_descriptor:"新描述符",
                        add_descriptor:"新增描述符",
                        view_descriptor:"查看描述符",
                        ertransactionmatches:"找到ER交易匹配",
                        descriptor:"描述符",
                        cardnumber:"卡號",
                        transaction_date:"交易日期",
                        transaction_currency:"交易幣種",
                        transaction_amount:"交易金額",
                        arn:"ARN",
                        authcode:"授權碼",
                        disputeconditioncode:"爭議條件代碼",
                        issuername:"發行人名稱",
                        generatetestalert:"產生測試警報",
                        process:"過程",
                        sent_data:"傳送數據",
                        response_data:"回應數據",
                        standard_alerts:"標準警報",
                        rdr_alerts:"RDR 警報",
                        updateenrollment:"更新註冊",
                        acquiring_bank:"收單銀行",
                        new_acquiring_bank:"新收單銀行",
                        add_acquiring_bank:"新增收單銀行",
                        view_acquiring_bank:"查看收單銀行",
                        bank_name:"銀行名",
                        state:"州",
                        country:"國家",
                        new_acquiring_bank_account:"新收單銀行帳戶",
                        add_acquiring_bank_account:"新增收單銀行帳戶",
                        view_acquiring_bank_account:"查看收單銀行帳戶",
                        bin:"Bin",
                        mid:"MID",
                        new_theme:"新主題",
                        add_theme:"新增主題",
                        view_theme:"查看主題",
                        from_name:"來自名字",
                        from_email_address:"寄件者電子郵件地址",
                        theme_name:"主題名稱",
                        theme_id: "主題 ID",
                        template_id: "範本 ID",
                        template_code: "模板程式碼",
                        new_email_template:"新電子郵件模板",
                        add_email_template:"新增電子郵件模板",
                        view_email_template:"查看電子郵件模板",
                        dashboard_url:"儀表板 Url",
                        statement_id:"對帳單 Id",
                        provider_statement_id:"提供者對帳單 Id",
                        invoice_number:"發票編號",
                        credit_status:"信用狀況",
                        received_date:"收到的日期",
                        requested_date:"申請日期",
                        status_update_date:"狀態更新日期",
                        credit_reason:"信用原因",
                        status_reason:"狀態原因",
                        comment:"評論",
                        merchant_id:"商家 Id",
                        merchant_name:"商家名稱",
                        created_date:"建立日期",
                        alert_api_url:"警報 API 網址",
                        api_user_name:"API 用户名",
                        api_password:"API 密碼",
                        default_dispute_action:"預設爭議處理",
                        alert_email:"警報電子郵件",
                        alert_trigger_email:"警報觸發電子郵件",
                        theme:"主題",
                        max_refund_amount:"快速解決 Tx 最大值",
                        eligible_for_refund:"有資格申請信用",
                        new_credit_request:"新的信用請求",
                        add_credit_request:"新增信用請求",
                        view_credit_request:"查看信用請求",
                        alert_provider_id:"警報提供者 Id",
                        reason:"原因",
                        action: "行動",
                        gateway_tx_date: "網關交易日期",
                        gateway_tx_id: "網關交易 Id",
                        gateway_tx_authcode:"網關授權碼",
                        gateway_tx_status:"網關交易狀態",
                        merchant_tx_id:"商家交易ID",
                        transaction_info:"交易資訊",
                        match_info:"匹配分資訊",
                        new_issuerbin:"新發行人Bin",
                        add_issuerbin:"新增發行人Bin",
                        view_issuerbin:"查看發行人Bin",
                        brand:"品牌",
                        type:"類型",
                        level:"等級",
                        isoa2:"ISO A2",
                        isoa3:"ISO A3",
                        isonumber:"ISO 編號",
                        www:"WWW",
                        phone:"電話",
                        delimiter:"分隔符",
                        header_row_present:"標題行存在?",
                        merchant:"商家",
                        provider:"提供者"
                    }
                },
                descriptor:{
                    details:"細節",
                    variations:"多樣化",
                    descriptor:"描述符",
                    form:{
                        new_descriptor: "新描述符",
                        add_descriptor: "新增描述符",
                        view_descriptor: "查看描述符",
                        submit:"提交",
                        submitting:"正在提交",
                        cancel:"取消",
                        descriptor_id : "描述符 ID",
                        descriptor : "描述符",
                        active: "活躍",
                        merchant_name:"商家名稱"
                    }
                },
                paymentlink: {
                    title: '付款連結',
                    title_not_found: '沒有與您的帳戶關聯的網站。',
                    new_paymentlink: '支付連結',
                    form: {
                        sid: "安全識別碼",
                        amount: "數量",
                        currency: '貨幣',
                        payment_description: '付款說明',
                        expiry_date: '到期日期',
                        first_name: '名',
                        last_name: '姓',
                        email: '電子郵件',
                        phone: '電話',
                        address:'地址',
                        city: 'City',
                        state: '城市',
                        country: '國家',
                        please_select_site: "請選擇站點",
                        please_select_currency: "請選擇貨幣",
                        please_select_country: "請選擇國家",
                        cancel: '取消',
                        submit: '提交',
                    }
                }
            },
            tables: {
                fields: {
                    id: "身分證",
                    _id: "ID",
                    arn: "ARN",
                    txid: "交易ID",
                    name: "名字",
                    time: "時間",
                    fees: "費用",
                    files: "檔案文件",
                    amount: "數量",
                    reason: "原因",
                    action: "行動",
                    status: "狀態",
                    subject: "主題",
                    outcome: "結果",
                    reserve: "備用金",
                    message: "消息",
                    card_no: "卡號",
                    currency: "貨幣",
                    merchant: "商人",
                    reseller: "轉銷商",
                    detail_id: "詳細ID",
                    sitename: "網站名稱",
                    site_name: "網站名稱",
                    site_status: "站點狀態",
                    rcode: "Rcode",
                    retailer_name: "零售商名稱",
                    timestamp: "時間",
                    cardnumber: "卡號",
                    alert_type: "警示類型",
                    alertdate: "警示日期",
                    descriptor: "描述符",
                    descriptor_text: "描述文字",
                    alert_descriptor: "警示描述符",
                    bank_mid: "銀行 MID",
                    reason_code: "代碼",
                    cardno: "卡號",
                    card_number: "卡號",
                    created_date: "建立日期",
                    reseller_name: "轉銷商",
                    site_receive: "站點接收",
                    merchantname: "商家名稱",
                    merchant_name: "商家名稱",
                    descriptor_title: "描述符標題",
                    pay_method: "支付方式",
                    payment_methods: "支付方式",
                    transactiondate: "交易日期",
                    transaction_date: "交易日期",
                    response_message: "回應訊息",
                    merchant_request: "商家請求",
                    merchant_response: "商家回應",
                    merchant_updatedate: "商家更新日期",
                    merchant_transaction_id: "商家交易編號",
                    matchid: "ID",
                    gwname: "閘道",
                    alertid: "警示ID",
                    orderid: "訂單編號",
                    datecreated: "日期",
                    refundstatus: "退款狀態",
                    gw_transaction_date: "GW 交易日期",
                    gw_transactionid: "GW 交易編號",
                    retrieval_date:"檢索日期",
                    provider_caseid: "提供者參考",
                    updated_by: "更新者",
                    txshieldoicartreference: "TxFind 購物車參考",
                    lookupreference: "尋找參考",
                    lookupdate: "查閱日期",
                    receiveddata: "接收數據",
                    returndata: "返回數據",
                    merchantid: "商家編號",
                    merchantref: "商家參考",
                    paymentdescriptor: "描述符",
                    authorizationcode: "授權碼",
                    authorizationdate: "授權日期",
                    authorizationamount: "授權金額",
                    settlementdate: "結算日期",
                    settlementamount: "結算金額",
                    dateofpurchase: "購買日期",
                    amountofpurchase: "購買金額",
                    creditcardnumber: "信用卡號碼",
                    recurringtransaction: "經常性交易",
                    productname: "產品名稱",
                    productdescription: "產品描述",
                    producturl: "產品網址",
                    unitpriceamount: "單價",
                    quantity: "數量",
                    memberrewardsnumber: "會員獎勵號碼",
                    manifest: "顯現",
                    reservationnumber: "預約號碼",
                    reservationdate: "預訂日期",
                    checkindate: "登記日期",
                    checkoutdate: "離開日期",
                    shippingcarrierurl: "承運人網址",
                    trackingnumber: "追蹤號碼",
                    dateofshipment: "出貨日期",
                    dateofdelivery: "送貨日期",
                    signatureconfirmation: "簽名確認",
                    proofofdelivery: "交貨證明",
                    proofofusage: "使用證明",
                    locationofusage: "使用地點",
                    frequencyofusage: "使用頻率",
                    dateofinteraction: "互動日期",
                    contactname: "聯絡人姓名",
                    notes: "筆記",
                    provider: "提供者",
                    providername: "提供者名稱",
                    requested_date: "申請日期",
                    received_date: "收到的日期",
                    status_update_date:"狀態更新日期",
                    comment: "評論",
                    status_reason: "狀態原因",
                    to: "到",
                    type: "類型",
                    date: "日期",
                    keyword: "關鍵字",
                    field: "場地",
                    total: "全部的",
                    item_no: "編號",
                    tx_items: "TX 物品",
                    per_unit: "每單位",
                    description: "描述",
                    trackstatus: "追蹤狀態",
                    trackingstatus: "追蹤狀態",
                    comm_to: "通訊至",
                    comm_status: "通訊狀態",
                    tx_action: "發送動作",
                    default_gateway: "預設網關",
                    testmethod: "測試方法",
                    mcc: "MCC",
                    bin: "Bin",
                    caid: "Caid",
                    ruleset: "Ruleset",
                    verifi: "Verifi CDRN",
                    ethoca: "Ethoca",
                    rdr: "RDR",
                    requestedDate: "申請日期",
                    updatedDate: "更新日期",
                    referenceNumber: "參考編號。",
                    expiry_date: "到期日",
                    request: "要求",
                    response: "回覆",
                    datetime: "日期時間",
                    matchscore: "匹配分",
                    alertstatus:"警報狀態",
                    rdractivity: "RDR 交流城市",
                    card_type: "卡的種類",
                    mid: "MID",
                    reasoncode:"原因代碼",
                    alerts:"警報",
                    transactionprocessing:"事務處理",
                    classfile:"類別文件",
                    apiurl:"接口地址",
                    active:"活躍",
                    autoimport:"自動導入",
                    version:"版本",
                    token:"令牌",
                    issued_at_date:"發行日期",
                    alertprovider: "警報提供者",
                    tier:"等級",
                    alert_provider_name:"提供者名稱",
                    email:"電子郵件",
                    response_expiry:"響應到期(小時)",
                    enrollment_date:"註冊日期",
                    path:"資料夾路徑",
                    url:"URL",
                    username:"用户名",
                    gateway_name:"網關名稱",
                    gateway_reference:"網關參考",
                    status_code:"狀態碼",
                    admin_only:"限管理員",
                    refunded:"已退款",
                    send_data:"傳送數據",
                    response_data:"回應數據",
                    post_url:"傳送 Url",
                    bank_name:"銀行名稱",
                    state:"州",
                    country:"国家",
                    retry_date:"重試日期",
                    success:"成功",
                    from_name:"來自名字",
                    from_email_address:"寄件者電子郵件地址",
                    theme_name:"主題名稱",
                    theme_id: "主題 ID",
                    template_id: "範本 ID",
                    template_code: "模板程式碼",
                    credit_created_date:"信用創建日期",
                    actioned:"已採取行動",
                    not_actioned:"未採取行動",
                    new:"新的",
                    marked_alert:"標記警報",
                    refund_status:"退款狀態",
                    match_score:"匹配分",
                    gateway_tx_status:"交易狀態",
                    gateway_tx_id:"網關交易 ID",
                    brand:"品牌",
                    condition_code:"條件代碼",
                    network:"網路",
                    dispute_condition:"爭議情況",
                    category:"類別",
                    rdr_alerts:"RDR 警報",
                    templateid:"範本 Id",
                    email_subject:"電子郵件主題",
                    send_to:"發給",
                    result:"結果",
                    message_id:"資訊 Id",
                    process_file_name:"檔案名稱",
                    process_name:"行程名稱",
                    data:"數據",
                    balance: "餘額",
                    auth_code: "授權碼",
                    caseid: "案例ID",
                    alert_id: "警報ID",
                    linked_provider_caseid: "提供者參考",
                    case_event: "案例活動",
                    file_download_date: "文件下載日期",
                    case_received_date: "案件受理日期",
                    payment_description: '付款說明',
                    link_text: '關聯',
                    first_name: '名',
                    last_name: '姓',
                    phone: '電話',
                    address: '地址',
                    city: '城市',
                    payment_status: '付款狀態',
                    show_delete_only: '仅显示删除',
                    delete_case_id: '柏德特医生 D',
                    delete_received_date: '它是以正确的方式建造的',
                    contact: '接触',
                    remarks: '评论',
                    verbose: '详细模式'
                },
                actions: {
                    ok: "好的",
                    view: "查看",
                    date: "日期",
                    items: "專案",
                    reset: "重置",
                    match: "匹配",
                    update: "更新",
                    search: "尋找",
                    export: "出口",
                    resolve: "解決",
                    download: "下載",
                    selected: "已選擇",
                    clear_all: "全部清除",
                    date_range: "日期範圍",
                    export_all: "全部導出",
                    more_details: "更多細節",
                    clear: "清除",
                    cancel: "取消",
                    clear_filters: "清除篩選器",
                    export_fields: "導出字段",
                    export_headers: "匯出標頭",
                    export_settings: "匯出設定",
                    export_selected: "選擇導出",
                    view_alert_data: "查看警示數據",
                    action_required: "需要採取的行動",
                    wip: "WIP",
                    document_list: "文件清單",
                    document_upload: "文件上載",
                    clear_filters_sorters: "清除篩選和分類",
                    hide_column: "隱藏列",
                    advanced_search: "進階搜尋",
                    export_error: "導出錯誤",
                    edit:"Edit",
                    mark:"Mark"
                },
                filters: "篩選器",
                sorters: "分類器",
                loading: "載入中",
                emptytext: "應用過濾器後，有些資料被隱藏",
                emptytext_email_to_3ds: "電子郵件支援開始使用3DS",
                display: "展示",
                show_total: "{{from}}-{{to}} 的 {{total}} 项目",
            },
            outcomes: {
                ok: "好的",
                wip: "工作正在進行中",
                other: "其他",
                error: "錯誤",
                cancel: "取消",
                pending: "待定",
                matched: "匹配的",
                declined: "已拒絕",
                resolved: "已解決",
                duplicate: "複製",
                processing: "加工中",
                "e-commerce": "電子商務",
                "manual entry": "手動輸入",
                manual_receive: "手動接收",
                merchant_receive: "商家接收",
                cb_proceeded: "進行退款",
                unactioned: "未採取行動",
                shipper_contacted:"聯絡托運人",
                missed:"錯過了",
                previously_cancelled:"先前已取消",
                previously_refunded:"之前已退款",
                unresolved_dispute:"未解決的爭議",
                notfound:"未找到",
                account_suspended:"帳戶被暫停",
                partially_stopped:"部分停止",
                stopped:"已停止",
                previously_received_chargeback:"之前收到的退款",
                account_closed:"帳戶已關閉",
                descriptor_does_not_belong_to:"描述符不屬於",
                refund_failed:"退款失敗", 
                cancelled:"取消", 
            },
            specialset: {
                other: "其他",
                matched: "相符",
                pending: "待定",
                declined: "已拒絕",
                resolved: "已解決",
                processing: "進行中",
                update: "更新",
                "action required": "需要採取行動",
                refunded: "已退款",
                not_refunded: "不退款",
                refund_exists: "存在退款",
                cannot_be_refunded: "無法退款",
                partial_refund_exists: "存在部分退款",
                chargeback_exists: "退款存在",
                marked_alert:"標記警報",
                not_marked:"未標記",
                void_exists: "虛空存在"
            },
            refundstatus: {
                refunded: "已退款",
                not_refunded: "不退款",
                refund_exists: "存在退款",
                void_exists: "虛空存在"
            },
            reason_descriptions: {
                the_case_has_been_resolved_with_the_customer_no_chargeback_is_expected_refund_is_being_processed_or_no_refund_needed: "該案件已與客戶解決。 預計不會出現退款情況。 正在處理退款，或無需退款。",
                case_resolved_credit__cancellation_processed: "案例已解決，信用額與取消已處理",
                case_resolved_partial_credit__cancellation_processed: "案例已解決，部分信用額和取消已處理",
                case_resolved_authorization_cancelled: "案例已解決，授權已取消",
                account_cancelledsuspended: "帳戶已取消/暫停",
                account_cancelled__suspended: "帳戶已取消/暫停",
                cancellation_processed: "取消已處理",
                transaction_previously_credited_for_case_amount_no_balance_remaining: "交易已在以前返還交易金額，沒有餘額",
                contacted_the_shipper_and_attempting_intercept_of_goods: "聯繫托運人並試圖攔截貨物",
                duplicate_request: "重複請求",
                merchant_account_closed_unable_to_process_credit: "商家帳戶已關閉，無法處理信用額",
                transaction_previously_received_a_chargeback_no_balance_remaining: "交易以前已收到拒付，沒有餘額",
                could_not_honour_cancel_request: "無法接受取消請求",
                matched_unable_to_stop_order_fulfilment: "已匹配，無法停止訂單履行",
                matched_proceed_with_chargeback_for_resolution: "已匹配，繼續進行拒付以解決問題",
                unmatched_case__generalother: "無法匹配的案例-一般/其他",
                unmatched_case__unable_to_locate_original_transaction: "無法匹配的案例-無法找到原始交易",
                request_is_outside_of_eligibility_timeframe: "請求超出有效時間範圍",
                transaction_3d_secure_authenticated_successfully_verified_by_visamastercard_secure_code:
                    "Transaction 3D Secure已成功通過身份驗證（由Visa / MasterCard安全代碼驗證）",
                work_in_progress: "工作正在進行中",
                error_details: "錯誤詳情",
                unable_to_process_refund: "無法處理退款",
                card_cancelled_ndash_unable_to_process_refund: "卡已取消 - 無法處理退款",
                error_transaction_declined_transaction_was_not_originally_billable: "錯誤交易被拒絕。 交易最初不是可計費的",
                unmatched_case__descriptor_does_not_belong_to_merchant: "大小寫不匹配 - 描述符不屬於商家",
                case_resolved:"Case Resolved",
                transaction_previously_credited_for_case_amount:"先前計入案件金額的交易"
            },
            alert_type: {
                "confirmed fraud": "確認詐欺",
                "customer dispute": "客戶糾紛",
                dispute: "爭議",
                dispute_notice: "爭議通知",
                dispute_rdr: "爭議_rdr",
                fraud_notice: "詐欺通知",
            },
            required_field: "這是一個必填字段",
            required_sid_field: "站點為必填字段",
            please_select: "請選擇",
            min_1_day:"日期範圍無效",
            max_3_months: "日期範圍最長應為 3 個月",
            today: "今天",
            last_7_days: "過去 7 天",
            last_30_days: "過去 30 天",
            this_month: "這個月",
        },
    },
};
