import React, { useState, useEffect } from "react";
import { THREEDS_URL } from "../../config/config";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal, Button, Form, Select, message, Upload } from "antd";

import { ImportOutlined, InboxOutlined } from "@ant-design/icons";


const { Option } = Select;
const { Dragger } = Upload;

const ImportBinMidModal = (props) => {
    let { t, fetchBinMid, siteList } = props;
    // const [fileList, setFileList] = useState([]); // commented due to unused var
    // const [state, setState] = useState({ export_header: false,  export_filetype: 'xlsx' });  // commented due to unused var
    const [formData, setFormData] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form] = Form.useForm();

    const initFormData = {
        file_csvfile: "",
        sid: null
    };

    const layout = {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 },
    };

    const formValInit = () => {
        setFormData(initFormData);
    };

    const showModal = () => {
        formValInit();
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        onFinish();
        setIsSubmitting(true);
    };

    const isOkButtonProps = () => false; //!formData || !formData.file_csvfile || !formData.sid;

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formValChange = (chgV, allV) => {
        let newFormData = {...formData, ...allV };
        setFormData(newFormData);
        form.setFieldsValue(allV);
    };

    const uploadConfig = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        headers: { 
            Authorization: "Bearer " + localStorage.getItem("threeds_token"),
            // "Content-Type": "multipart/form-data",
            // "Accept": "application/json"
        },
        data: { varname: 'file', MAX_FILE_SIZE: 10000000},
        action: THREEDS_URL+'/api/v1/3ds/fupload',
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (status === 'done') {
            
            message.success(`${info.file.name} file uploaded successfully.`);
            let newFormData = {...formData, file_csvfile : info.file.response };
            setFormData(newFormData);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
        onRemove(e){
            let newFormData = {...formData, file_csvfile : '' };
            setFormData(newFormData);
        }
      };

    const onFinish = () => {
       
        const apiUrl = THREEDS_URL + `/api/v1/3ds/binmid/import`;
          
        axios
            .post(apiUrl, formData, { headers: { 
                Authorization: "Bearer " + localStorage.getItem("threeds_token"),
                // "Content-Type": "multipart/form-data",
                // "Accept": "application/json"
            } })
            .then(({ data }) => {
                if (data.status.toLowerCase() === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: t("contents.3dsecure.binmid.added"),
                        text: data.details ? data.details : "",
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchBinMid();
                            setIsSubmitting(false);
                            form.resetFields();
                        },
                    });
                    handleCancel();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("login.dialogs.catch"),
                        text: data.msg ? data.msg : "",
                        showConfirmButton: true,
                    });
                    setIsSubmitting(false);
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.error_msg.details : "",
                    showConfirmButton: true,
                });
                setIsSubmitting(false);
            });
    }
// react ant design file upload formdata to php?

    return (
        <>
        <Button key={`btn_import`} type="link" onClick={showModal} >
            <div className="d-flex align-items-center">
                <ImportOutlined style={{ fontSize: "18px" }} /> <div style={{ marginLeft: "4px" }}>Import CSV</div>
            </div>
        </Button>
        <Modal
            title={t("contents.3dsecure.imports_binmid")}
            width={600}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={() => handleCancel()}>
                    {t("contents.chargebacks.form.cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={isSubmitting} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                    {isSubmitting ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                </Button>,
            ]}
        >
            <Form form={form} {...layout} name="importbinmid" onValuesChange={formValChange}>
                {/* <Form.Item name="csv_loc" label={t("contents.3dsecure.form.bin")} rules={[{ required: true }]}>
                    <Upload
                        {...uploadConfig}
                    >
                    <Button>Choose File</Button>
                    </Upload>
                </Form.Item> */}
                <Dragger {...uploadConfig}>
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                    banned files.
                    </p>
                </Dragger>
                <Form.Item name="sid" label={t("contents.3dsecure.form.site_name")} rules={[{ required: true }]}>
                    <Select
                            showSearch
                            showArrow="true" 
                            placeholder={t("contents.3dsecure.form.please_select")}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}>
                        {siteList
                            ? siteList.map((item) => (
                                    <Option key={item.sid} value={item.sid}>
                                        {item.name}
                                    </Option>
                                ))
                            : null}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
        </>
    );
};

export default ImportBinMidModal;
