import React, { useEffect, useState } from 'react'
import { Modal } from 'antd';
import { Form } from 'antd';
import { Input } from 'antd';
import { Select } from 'antd';
import { Row } from 'antd';
import { Col } from 'antd';
import { Button } from 'antd';
import axios from 'axios';
import { THREEDS_URL } from '../../config/config';
import Swal from 'sweetalert2';
import CatchError from '../../helper/CatchError';
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import { Checkbox } from 'antd';

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const { Option } = Select;

const AddMerchantModal = (props) => {

    const { t, isAddMerchantModalVisible, setIsAddMerchantModalVisible, paymentLink, countryList, merchantData, reloadTable, clientModeOption, enableResultOption, disableChallengeOption, enable3ds1FrictionLessOption, enable3ds1OnlyOption, authenticationIndicatorOption, challengeIndicatorOption, accountTypeOption, messageCategoryOption, transactionTypeOption } = props;
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState();
    useEffect(() => {
        if (merchantData) {
            form.setFieldsValue({
                name: merchantData?.name,
                description: merchantData?.description,
                contact: merchantData?.contact,
                remarks: merchantData?.remarks,
                status: merchantData?.status,
                verbose: (merchantData?.verbose) ? true : false,
                client_mode: merchantData?.clientMode,
                enable_result: merchantData?.enableResult,
                disable_challenge: merchantData?.disableChallenge,
                enable_3ds1_frictionless: merchantData?.enable3ds1Frictionless,
                enable_3ds1_only: merchantData?.enable3DS1Only,
                requestor_name: merchantData?.requestorName,
                requestor_url: merchantData?.requestorURL,
                authentication_ind: merchantData?.authenticationInd,
                challenge_ind: merchantData?.challengeInd,
                acct_type: merchantData?.acctType,
                visaAcquirerMid: merchantData?.visaAcquirerMid,
                visaAcquirerBin: merchantData?.visaAcquirerBin,
                masterAcquirerBin: merchantData?.masterAcquirerBin,
                masterAcquirerMid: merchantData?.masterAcquirerMid,
                JCBAcquirerBin: merchantData?.JCBAcquirerBin,
                JCBAcquirerMid: merchantData?.JCBAcquirerMid,
                CUPAcquirerBin: merchantData?.CUPAcquirerBin,
                CUPAcquirerMid: merchantData?.CUPAcquirerMid,
                AMEXAcquirerBin: merchantData?.AMEXAcquirerBin,
                AMEXAcquirerMid: merchantData?.AMEXAcquirerMid,
                acquirer_merchant_pwd: merchantData?.acquirerMerchantPwd,
                mcc: merchantData?.mcc,
                merchant_country_code: merchantData?.merchantCountryCode,
                message_category: merchantData?.messageCategory,
                purchase_currency: merchantData?.purchaseCurrency,
                trans_type: merchantData?.transType,
                three_ds_provider: merchantData?.three_ds_provider,
                threedsprotocolversion: merchantData?.threedsprotocolversion,
                hitrust_account_id: merchantData?.hitrust_account_id,
                hitrust_password: merchantData?.hitrust_password,
                hitrust_base_url: merchantData?.hitrust_base_url,
                hitrust_mastercard_prefix: merchantData?.hitrust_mastercard_prefix,
            });
        } else {

            form.setFieldsValue({
                name: null,
                description: null,
                contact: null,
                remarks: null,
                status: 'active',
                verbose: null,
                client_mode: '2',
                enable_result: 'Y',
                disable_challenge: 'N',
                enable_3ds1_frictionless: 'N',
                enable_3ds1_only: 'N',
                requestor_name: null,
                requestor_url: null,
                "authentication_ind": '01',
                "challenge_ind": '01',
                acct_type: '01',
                visaAcquirerBin: null,
                visaAcquirerMid: null,
                masterAcquirerBin: null,
                masterAcquirerMid: null,
                JCBAcquirerBin: null,
                JCBAcquirerMid: null,
                CUPAcquirerBin: null,
                CUPAcquirerMid: null,
                AMEXAcquirerBin: null,
                AMEXAcquirerMid: null,
                acquirer_merchant_pwd: null,
                mcc: null,
                merchant_country_code: null,
                merchant_name: null,
                message_category: '01',
                purchase_currency: null,
                trans_type: '01',
                "three_ds_provider": 'hitrust',
                "threedsprotocolversion": null,
                hitrust_account_id: null,
                hitrust_password: null,
                hitrust_base_url: null,
                hitrust_mastercard_prefix: null
            })
        }

    }, [merchantData, form])

    const handleCancel = () => {
        setIsAddMerchantModalVisible(false);
    }

    const handleSubmit = () => {
        setIsLoading(true);
        form.validateFields().then((values) => {

            if (merchantData)
                values.bin_mid_id = merchantData?.bin_mid_id;

            let route = (merchantData) ? `${merchantData.mid}/edit` : 'create';
            const apiUrl = THREEDS_URL + '/api/v1/merchant/' + route;
            axios
                .post(apiUrl, values, { headers: { Authorization: "Bearer " + localStorage.getItem("threeds_token") } })
                .then(({ data }) => {
                    if (data?.status.toLowerCase() === 'ok') {
                        Swal.fire({
                            icon: 'success',
                            title: (merchantData) ? '3DS merchant updated' : '3DS merchant created',
                            text: data.link,
                            showConfirmButton: true,
                            reverseButtons: true
                        })
                        if (!merchantData)
                            form.resetFields();
                        setIsLoading(false);
                        reloadTable()
                    } else {
                        setIsLoading(false);
                        CatchError('Unable to create 3ds merchant.')
                    }
                }).catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    CatchError('Unable to create 3ds merchant.')
                })
        }).catch((info) => {
            setIsLoading(false);
        })
    }

    const formItemStyle = {
        marginBottom: "10px"
    };

    return (
        <Modal
            forceRender={true}
            title={merchantData ? t("contents._3dsecure_merchant.edit_merchant") : t("contents._3dsecure_merchant.new_merchant")}
            width="1176"
            style={{
                maxWidth: "1176px"
            }}
            visible={isAddMerchantModalVisible}
            onOk={handleSubmit}
            okText={"Submit"}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={() => handleCancel()}>
                    {t("contents.paymentlink.form.cancel")}
                </Button>,
                ...!paymentLink ? [<Button key="submit" type="primary" onClick={handleSubmit} htmlType="submit" loading={isLoading}>
                    {t("contents.paymentlink.form.submit")}
                </Button>] : []
            ]}
        >

            <Form  {...{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                layout: "horizontal",
                size: "middle",
                labelAlign: "right",
                labelWrap: false,
                requiredMark: true,
                colon: false,
                style: { padding: "10px 0" }
            }}
                form={form}
            >

                <Row justify="space-around">
                    <Col lg={23}>

                        {/* NAME INPUT */}
                        <Form.Item style={formItemStyle} name="name" label={t('contents._3dsecure_merchant.form.name')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* DESCRIPTION INPUT */}
                        <Form.Item style={formItemStyle} name="description" label={t('contents._3dsecure_merchant.form.description')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* CONTACT INPUT */}
                        <Form.Item style={formItemStyle} name="contact" label={t('contents._3dsecure_merchant.form.contact')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* REMARKS INPUT */}
                        <Form.Item style={formItemStyle} name="remarks" label={t('contents._3dsecure_merchant.form.remarks')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* STATUS INPUT */}
                        <Form.Item style={formItemStyle} name="status" label={t('contents._3dsecure_merchant.form.status')} rules={[{ required: true, message: t('required_field') }]}>
                            <Select
                                placeholder={t('please_select')}
                                showSearch
                                optionFilterProp="children"
                            >
                                <Option key="active" value="active"> {t('contents._3dsecure_merchant.form.status_option_active')} </Option>
                                <Option key="deactivated" value="deactivated"> {t('contents._3dsecure_merchant.form.status_option_deactivated')} </Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row justify="space-around" style={{ marginTop: '20px' }}>

                    <Col lg={11}>

                        {/* VERBOSE MODE INPUT */}
                        <Form.Item label={t('contents._3dsecure_merchant.form.verbose_mode')} name="verbose" valuePropName="checked" style={{ marginBottom: ".5rem" }}>
                            <Checkbox />
                        </Form.Item>

                        {/* CLIENT MODE INPUT */}
                        <Form.Item name="client_mode" label={t('contents._3dsecure_merchant.form.client_mode')} rules={[{ required: true, message: t('required_field') }]}>
                            <Select
                                placeholder={t('please_select')}
                                showSearch
                                optionFilterProp="children"
                            >
                                {clientModeOption.map((item, index) => (
                                    <Option key={item.key} value={item.value}> {item.label} </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* ENABLE RESULT */}
                        <Form.Item name="enable_result" label={t('contents._3dsecure_merchant.form.enable_result')} rules={[{ required: true, message: t('required_field') }]}>
                            <Select
                                placeholder={t('please_select')}
                                showSearch
                                optionFilterProp="children"
                            >
                                {enableResultOption.map((item, index) => (
                                    <Option key={item.key} value={item.value}> {item.label} </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* DISABLE CHALLENGE */}
                        <Form.Item name="disable_challenge" label={t('contents._3dsecure_merchant.form.disable_challenge')}>
                            <Select
                                placeholder={t('please_select')}
                                showSearch
                                optionFilterProp="children"
                            >
                                {disableChallengeOption.map((item, index) => (
                                    <Option key={item.key} value={item.value}> {item.label} </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* ENABLE 3DS1 FRICTIONLESS */}
                        <Form.Item name="enable_3ds1_frictionless" label={t('contents._3dsecure_merchant.form.enable_3ds1_friction_less')}>
                            <Select
                                placeholder={t('please_select')}
                                showSearch
                                optionFilterProp="children"
                            >
                                {enable3ds1FrictionLessOption.map((item, index) => (
                                    <Option key={item.key} value={item.value}> {item.label} </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* ENABLE 3DS1 ONLY */}
                        <Form.Item name="enable_3ds1_only" label={t('contents._3dsecure_merchant.form.enable_3ds1_only')}>
                            <Select
                                placeholder={t('please_select')}
                                showSearch
                                optionFilterProp="children"
                            >
                                {enable3ds1OnlyOption.map((item, index) => (
                                    <Option key={item.key} value={item.value}> {item.label} </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* REQUESTOR NAME */}
                        <Form.Item style={formItemStyle} name="requestor_name" label={t('contents._3dsecure_merchant.form.requestor_name')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* REQUESTOR URL */}
                        <Form.Item style={formItemStyle} name="requestor_url" label={t('contents._3dsecure_merchant.form.requestor_url')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* 3DS Requestor Authentication Indicator */}
                        <Form.Item name="authentication_ind" label={t('contents._3dsecure_merchant.form.3DS_requestor_authentication_indicator')} rules={[{ required: true, message: t('required_field') }]}>
                            <Select
                                placeholder={t('please_select')}
                                showSearch
                                optionFilterProp="children"
                            >
                                {authenticationIndicatorOption.map((item, index) => (
                                    <Option key={item.key} value={item.value}> {item.label} </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* 3DS Requestor Challenge Indicator */}
                        <Form.Item name="challenge_ind" label={t('contents._3dsecure_merchant.form.3DS_requestor_challenge_indicator')} rules={[{ required: true, message: t('required_field') }]}>
                            <Select
                                placeholder={t('please_select')}
                                showSearch
                                optionFilterProp="children"
                            >
                                {challengeIndicatorOption.map((item, index) => (
                                    <Option key={item.key} value={item.value}> {item.label} </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* Acct Type */}
                        <Form.Item name="acct_type" label={t('contents._3dsecure_merchant.form.acct_type')} rules={[{ required: true, message: t('required_field') }]}>
                            <Select
                                placeholder={t('please_select')}
                                showSearch
                                optionFilterProp="children"
                            >
                                {accountTypeOption.map((item, index) => (
                                    <Option key={item.key} value={item.value}> {item.label} </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* Visa Acquiring Bank BIN */}
                        <Form.Item style={formItemStyle} name="visaAcquirerBin" label={t('contents._3dsecure_merchant.form.visa_acquiring_bank_bin')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* Visa Acquirer Merchant ID */}
                        <Form.Item style={formItemStyle} name="visaAcquirerMid" label={t('contents._3dsecure_merchant.form.visa_acquirer_merchant_id')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* Mastercard Acquiring Bank BIN */}
                        <Form.Item style={formItemStyle} name="masterAcquirerBin" label={t('contents._3dsecure_merchant.form.mastercard_acquiring_bank_bin')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* Mastercard Acquirer Merchant ID */}
                        <Form.Item style={formItemStyle} name="masterAcquirerMid" label={t('contents._3dsecure_merchant.form.mastercard_acquirer_merchant_id')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* JCB Acquiring Bank Bin */}
                        <Form.Item style={formItemStyle} name="JCBAcquirerBin" label={t('contents._3dsecure_merchant.form.jcb_acquiring_bank_bin')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                    </Col>

                    <Col lg={11}>

                        {/* JCB Acquirer Merchant ID */}
                        <Form.Item style={formItemStyle} name="JCBAcquirerMid" label={t('contents._3dsecure_merchant.form.jcb_acquirer_merchant_id')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* CUP Acquiring Bank BIN */}
                        <Form.Item style={formItemStyle} name="CUPAcquirerBin" label={t('contents._3dsecure_merchant.form.cup_acquiring_bank_bin')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* CUP Acquirer Merchant ID */}
                        <Form.Item style={formItemStyle} name="CUPAcquirerMid" label={t('contents._3dsecure_merchant.form.cup_acquirer_merchant_id')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* AMEX Acquiring Bank BIN */}
                        <Form.Item style={formItemStyle} name="AMEXAcquirerBin" label={t('contents._3dsecure_merchant.form.amex_acquiring_bank_bin')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* AMEX Acquirer Merchant ID */}
                        <Form.Item style={formItemStyle} name="AMEXAcquirerMid" label={t('contents._3dsecure_merchant.form.amex_acquirer_merchant_id')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* Acquirer Merchant ID Password */}
                        <Form.Item style={formItemStyle} name="acquirer_merchant_pwd" label={t('contents._3dsecure_merchant.form.acquirer_merchant_id_password')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* Merchant Category Code */}
                        <Form.Item style={formItemStyle} name="mcc" label={t('contents._3dsecure_merchant.form.merchant_category_code')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* Merchant Country Code */}
                        <Form.Item style={formItemStyle} name="merchant_country_code" label={t('contents._3dsecure_merchant.form.merchant_country_code')} rules={[{ required: true, message: t('required_field') }]}>
                            <Select
                                placeholder={t('please_select')}
                                showSearch
                                optionFilterProp="children"
                            >
                                {countryList
                                    ?
                                    Object.keys(countryList).map((item, index) => (
                                        <Option key={index} value={countryList[index].abrv_numeric}> {countryList[index].name} - {countryList[index].abrv_numeric} </Option>
                                    ))
                                    : null
                                }
                            </Select>
                        </Form.Item>

                        {/* Merchant Name */}
                        {/* <Form.Item style={formItemStyle} name="merchant_name" label={t('contents._3dsecure_merchant.form.merchant_name')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item> */}

                        {/* Message Category */}
                        <Form.Item name="message_category" label={t('contents._3dsecure_merchant.form.message_category')} rules={[{ required: true, message: t('required_field') }]}>
                            <Select
                                placeholder={t('please_select')}
                                showSearch
                                optionFilterProp="children"
                            >
                                {messageCategoryOption.map((item, index) => (
                                    <Option key={item.key} value={item.value}> {item.label} </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* Purchase Currency */}
                        <Form.Item style={formItemStyle} name="purchase_currency" label={t('contents._3dsecure_merchant.form.purchase_currency')} rules={[{ required: true, message: t('required_field') }]}>
                            <Select
                                placeholder={t('please_select')}
                                showSearch
                                optionFilterProp="children"
                            >
                                {countryList
                                    ?
                                    Object.keys(countryList).map((item, index) => (
                                        <Option key={index} value={countryList[index].abrv_numeric}> {countryList[index].name} - {countryList[index].abrv_currency} - {countryList[index].abrv_numeric} </Option>
                                    ))
                                    : null
                                }
                            </Select>
                        </Form.Item>

                        {/* Transaction Type */}
                        <Form.Item name="trans_type" label={t('contents._3dsecure_merchant.form.transaction_type')} rules={[{ required: true, message: t('required_field') }]}>
                            <Select
                                placeholder={t('please_select')}
                                showSearch
                                optionFilterProp="children"
                            >
                                {transactionTypeOption.map((item, index) => (
                                    <Option key={item.key} value={item.value}> {item.label} </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* 3DS three_ds_provider */}
                        <Form.Item style={formItemStyle} name="three_ds_provider" label={t('contents._3dsecure_merchant.form.3ds_provider')} rules={[{ required: true, message: t('required_field') }]}>
                            <Select
                                placeholder={t('please_select')}
                                showSearch
                                optionFilterProp="children"
                            >
                                <Option key="hitrust" value="hitrust"> {t('contents._3dsecure_merchant.form.3ds_provider_option_hitrust')} </Option>
                            </Select>
                        </Form.Item>

                        {/* 3DS Protocol Version */}
                        <Form.Item style={formItemStyle} name="threedsprotocolversion" label={t('contents._3dsecure_merchant.form.3ds_protocol_version')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* HiTrust Account Id */}
                        <Form.Item style={formItemStyle} name="hitrust_account_id" label={t('contents._3dsecure_merchant.form.hitrust_account_id')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* HiTrust Password */}
                        <Form.Item style={formItemStyle} name="hitrust_password" label={t('contents._3dsecure_merchant.form.hitrust_password')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* HiTrust Base Url */}
                        <Form.Item style={formItemStyle} name="hitrust_base_url" label={t('contents._3dsecure_merchant.form.hitrust_base_url')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>

                        {/* HiTrust Mastercard Requestor Prefix */}
                        <Form.Item style={formItemStyle} name="hitrust_mastercard_prefix" label={t('contents._3dsecure_merchant.form.hitrust_mastercard_requestor_prefix')} rules={[{ required: true, message: t('required_field') }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default AddMerchantModal